/*========================= section-withdraw */

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.section-login {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.section-login .login-info {
    width: 56vw;
    padding: 50px 350px;
    padding-bottom: 100px;
}

.section-login .login-info:not(.back-to){
    padding-top: 71px;
}

.section-login .login-info .back-to {
    font-size: 14px;
    font-weight: 600;
    color: #aaa;
    text-decoration: none;
    align-items: center;
    display: flex;
    outline: none;
    width: fit-content;
    cursor: pointer;
}

.section-login .login-info .back-to i {
    margin-right: 10px;
    font-size: 12px;
}

.section-login .login-info .title {
    margin-top: 125px;
}

.section-login .login-info .title span {
    font-size: 44px;
    font-weight: bold;
    color: #333;
}

.section-login .login-info .title p {
    color: #999;
    font-weight: 600;
    font-size: 15px;
}

.section-login .login-info .inputs {
    position: relative;
}

.section-login .login-info .inputs .label-with-info {
    display: flex;
    justify-content: start;
    align-items: baseline;
}

.section-login .login-info .inputs .password-info {
    margin-left: 8px;
    cursor: help;
    color: #bbb;
    font-size: 19px;
    transition: .4s;
}

.section-login .login-info .inputs .password-info:hover {
    color: #777;
}

.section-login .login-info .inputs .password-info-area {
    position: absolute;
    animation: open-animation 0.5s;
    background-color: #eee;
    color: #444;
    left: 115px;
    bottom: 43px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px #00000047;
    padding: 10px;
    padding-bottom: 5px;
    z-index: 99;
}

.section-login .login-info .inputs.new-password .password-info-area {
    left: 151px;
}

.section-login .login-info .inputs.sign-up .password-info-area {
    bottom: 150px;
}

.section-login .login-info .inputs.sign-up .password-show-hide {
    bottom: 166px;
}

.section-login .login-info .inputs.sign-up .password-show-hide.first {
    bottom: 275px;
}

.section-login .login-info .inputs .password-info-area .close {
    position: absolute;
    right: -6px;
    top: -6px;
    color: #777;
    cursor: pointer;
    transition: .4s;
}

.section-login .login-info .inputs .password-info-area .close:hover {
    color: #555;
}

.section-login .login-info .inputs .password-info-area div {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 5px;
    font-size: 14px;
}

.section-login .login-info .inputs .password-info-area div i {
    margin-right: 5px;
}

.section-login .login-info .inputs .password-info-area div i.danger {
    color: var(--global-danger-color);
}

.section-login .login-info .inputs .password-info-area div i.success {
    color: var(--global-success-color);
}

.section-login .login-info .inputs .password-show-hide {
    position: absolute;
    right: 0px;
    bottom: 55px;
    padding-right: 4px;
    cursor: pointer;
    color: #777;
    font-size: 14px;
}

.section-login .login-info .inputs .password-show-hide.first {
    bottom: 166px;
}

.section-login .login-info .inputs .password-show-hide i {
    margin-right: 6px;
    font-size: 13px;
}

.section-login .login-info .inputs:not(.verification) {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.section-login .login-info .inputs.verification {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding-right: 3px;
    max-width: 575px;
}

.section-login .login-info .inputs.verificationNormalLength {
    max-width: 100%;
}

.section-login .login-info .inputs.verification input {
    width: 75px;
    height: 75px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #eee;
    outline: none;
    text-align: center;
    font-size: 24px;
}

.section-login .login-info .inputs label {
    color: #777;
    font-weight: bold;
    margin-top: 30px;
    padding-left: 1px;
    padding-bottom: 6px;
}

.section-login .login-info .inputs label:first-child {
    margin-top: 0px;
}

.section-login .login-info .inputs .label-with-info label {
    margin-top: 30px;
}

.section-login .login-info .inputs label span {
    font-weight: 500;
    margin-left: 5px;
}

.section-login .login-info .inputs.new-password label {
    margin-top: 30px;
}

.section-login .login-info .inputs:not(.verification) input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #eee;
    outline: none;
    transition: .4s;
}

.section-login .login-info .inputs:not(.verification) input.danger {
    box-shadow: 0px 0px 5px var(--global-danger-color);
}

.section-login .login-info .inputs:not(.verification) input:-webkit-autofill,
.section-login .login-info .inputs:not(.verification) input:-webkit-autofill:hover,
.section-login .login-info .inputs:not(.verification) input:-webkit-autofill:focus,
.section-login .login-info .inputs:not(.verification) input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px #ccc inset !important;
    box-shadow: 0 0 0px 1000px #ccc inset !important;
    -webkit-text-fill-color: #333 !important;
    border-color: #ccc;
}

.section-login .login-info .inputs:not(.verification) input.reference-code {
    padding-right: 50px;
}

.section-login .login-info .inputs:not(.verification) .reference-code-icon {
    position: absolute;
    right: 15px;
    bottom: 15px;
    font-size: 18px;
}

.section-login .login-info .inputs:not(.verification) .reference-code-icon.success {
    color: var(--global-success-color);
}
.section-login .login-info .inputs:not(.verification) .reference-code-icon.danger {
    color: var(--global-danger-color);
}

.section-login .login-info .inputs-extra {
    padding: 15px 2px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.section-login .login-info .inputs-extra div {
    display: flex;
    align-items: center;
}

.section-login .login-info .inputs-extra input {
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border: 1px solid #aaa;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
    transition: .4s;
}

/*arka plan rengini değiştirebilmek için tik işaretini yukarıda gizledik, kendimiz oluşturuyoruz */
.section-login .login-info .inputs-extra input:checked::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    top: 2px;
    left: 7px;
}

.section-login .login-info .inputs-extra input:checked {
    background-color: #999;
}

.section-login .login-info .inputs-extra input:hover {
    border-color: #777;
}

.section-login .login-info .inputs-extra label {
    cursor: pointer;
    color: #333;
}

.section-login .login-info .inputs-extra a {
    text-decoration: none;
    color: #666;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.section-login .login-info .contract {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    margin-top: 50px;
}

.section-login .login-info .contract label {
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;
}

.section-login .login-info .contract label:first-child {
    margin-bottom: 10px;
}

.section-login .login-info .contract input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.section-login .login-info .contract .checkmark {
    height: 20px;
    width: 20px;
    background-color: #ddd;
    display: inline-block;
    margin-right: 8px;
    transition: .4s;
    border-radius: 5px;
}

.section-login .login-info .contract input:checked + .checkmark {
    background-color: #777;
}

.section-login .login-info .contract .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.section-login .login-info .contract input:checked + .checkmark:after {
    display: block;
}
  
.section-login .login-info .contract .checkmark:after {
    left: 6px;
    top: 4px;
    width: 4px;
    height: 11px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(36deg);
    border-radius: 1px;
}

.section-login .login-info .contract label .text {
    width: fit-content;
}

.section-login .login-info .contract .text a {
    color: #e6a300;
}

.section-login .login-info button {
    width: 100%;
    margin-top: 50px;
    padding: 12px;
    border-radius: 5px;
    border: 0px;
    background-color: #343434;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: .4s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-login .login-info button:hover {
    background-color: #484848;
}

.section-login .login-info button div div {
    height: 27px !important;
    width: 50px !important;
    display: flex;
    align-items: center;
}

.section-login .login-info button.verification {
    max-width: 575px;
}

.section-login .login-info .register {
    margin-top: 15px;
    font-size: 14px;
}

.section-login .login-info .register label {
    color: #888;
    font-weight: bold;
}

.section-login .login-info .register a {
    text-decoration: none;
    margin-left: 6px;
    color: #4c4c4c;
    font-weight: bold;
    cursor: pointer;
}

.section-login .login-info .result-message-area-main{
    margin: 40px 0px;
    min-height: 82px;
}

.section-login .login-info .result-message-area-main.verification {
    max-width: 575px;
}

.section-login .login-info .result-message-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-radius: 5px;
    color: #fff;
    font-size: 17px;
    width: 100%;
}

.section-login .login-info .result-message-area.success {
    background-color: var(--global-success-color);
}

.section-login .login-info .result-message-area.danger {
    background-color: var(--global-danger-color);
}

.section-login .login-info .result-message-area.success i.danger {
    display: none;
}

.section-login .login-info .result-message-area.danger i.success {
    display: none;
}

.section-login .login-info .result-message-area div {
    display: flex;
    align-items: center;
    justify-content: start;
}

.section-login .login-info .result-message-area div span {
    margin-left: 15px;
    padding-right: 15px;
}

.section-login .login-info .result-message-area .close {
    cursor: pointer;
}

.section-login .login-logo {
    background: linear-gradient(-45deg, #000000, #101010, #222222, #101010, #000000);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    width: 44vw;
    height: 100vh;
    border-bottom-left-radius: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0px;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.5);
}

.section-login .login-logo .background {
    background:url('../../images/login_background.jpg');
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.05;
    border-bottom-left-radius: 300px;
}

.section-login .login-logo img {
    width: 50%;
    padding-bottom: 5vw;
}

.section-login i.darkmode {
    position: absolute;
    right: 50px;
    bottom: 50px;
    border-radius: 50px;
    color: #fff;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    transition: .4s;
    background: linear-gradient(-45deg, #4c4c4c, #303030, #222222, #434343, #5e5e5e);
    z-index: 1;
}

.section-login i.darkmode:hover {
    box-shadow: 0px 0px 25px #bababa57;
}

.section-login i.darkmode2 {
    display: none;
}

/*========================= koyu tema düzenlemeleri */

body.dark .section-login .login-info .title span, body.dark .section-login .login-info .inputs-extra label {
    color: #ccc;
}

body.dark .section-login .login-info .back-to, body.dark .section-login .login-info .title p, body.dark .section-login .login-info .register label {
    color: #888;
}

body.dark .section-login .login-info .inputs label, body.dark .section-login .login-info .inputs .password-show-hide, body.dark .section-login .login-info .inputs-extra a, body.dark .section-login .login-info .register a {
    color: #aaa;
}

body.dark .section-login .login-info .inputs input{
    background-color: #333;
    color: #ddd;
}

body.dark .section-login .login-info .inputs input:-webkit-autofill,
body.dark .section-login .login-info .inputs input:-webkit-autofill:hover,
body.dark .section-login .login-info .inputs input:-webkit-autofill:focus,
body.dark .section-login .login-info .inputs input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px #555 inset !important;
    box-shadow: 0 0 0px 1000px #555 inset !important;
    -webkit-text-fill-color: #ddd !important;
    border-color: #555;
}

/* Webkit tarayıcıları için (örn. Safari ve Chrome) */
body.dark .section-login .login-info .inputs input::placeholder { 
    color: #888; 
    opacity: 1; /* Safari'de opacity varsayılan olarak 0.54'tür, bu nedenle bunu eklemekte fayda var */
}

/* Mozilla Firefox için */
body.dark .section-login .login-info .inputs input::-moz-placeholder {
    color: #888;
    opacity: 1;
}

/* Microsoft Edge ve Internet Explorer 10+ için */
body.dark .section-login .login-info .inputs input::-ms-input-placeholder {
    color: #888;
}

/* Opera 19+ için */
body.dark .section-login .login-info .inputs input:-ms-input-placeholder {
    color: #888;
}

body.dark .section-login .login-info .inputs-extra input:checked {
    background-color: #444;
    border-color: #555;
}

body.dark .section-login .login-info .contract .checkmark {
    background-color: #777;
}

body.dark .section-login .login-info .contract input:checked + .checkmark {
    background-color: #444;
}

body.dark .section-login .login-info .contract .checkmark:after {
    border-color: #ccc;
}

body.dark .section-login .login-info .contract .text a {
    color: var(--global-yellow-color);
}

body.dark .section-login .login-info button {
    background-color: #2b2b2b;
    color: #ddd;
}

body.dark .section-login .login-info button:hover {
    background-color: #333;
}

body.dark .section-login .login-info .inputs .password-info-area {
    background-color: #444;
    box-shadow: 0px 0px 10px #ffffff1c;
    color: #ccc;
}

body.dark .section-login .login-info .inputs .password-info-area .close {
    color: #888;
}

body.dark .section-login .login-info .inputs .password-info-area .close:hover {
    color: #aaa;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 2250px){
    .section-login .login-info {
        padding: 50px 15%;
        padding-bottom: 100px;
    }
}

@media screen and (max-width: 1800px){
    .section-login .login-info {
        padding: 50px 13%;
        padding-bottom: 100px;
    }
    .section-login .login-logo, .section-login .login-logo .background {
        border-bottom-left-radius: 250px;
    }
    .section-login .login-logo img {
        padding-bottom: 12vw;
    }
}

@media screen and (max-width: 1700px){
    .section-login .login-info .inputs.verification input {
        width: 70px;
        height: 70px;
    }
}

@media screen and (max-width: 1500px){
    .section-login .login-info {
        padding: 50px 10%;
        padding-bottom: 100px;
    }
    .section-login .login-logo img {
        padding-bottom: 20vw;
    }
}

@media screen and (max-width: 1200px){
    .section-login {
        flex-direction: column-reverse;
        position: relative;
        padding-bottom: 75px;
    }
    .section-login .login-info, .section-login .login-logo {
        width: 100%;
    }
    .section-login .login-info{
        padding-top: 500px;
        padding-bottom: 100px;
    }
    .section-login .login-info:not(.back-to) {
        padding-top: 410px;
    }
    .section-login .login-info button {
        margin-bottom: 50px;
    }
    .section-login .login-info .register {
        margin-top: -30px;
    }
    .section-login .login-logo {
        height: 450px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        top: 0px;
        position: absolute;
        z-index: -1;
    }
    .section-login .login-logo .background {
        height: 450px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
    }
    .section-login .login-logo img{
        padding: 50px;
    }
    .section-login i.darkmode {
        display: none;
    }
    .section-login i.darkmode2 {
        display: flex;
    }
}

@media screen and (max-width: 900px){
    .section-login .login-logo img {
        width: 75%;
    }
}

@media screen and (max-width: 750px){
    .section-login .login-info .inputs.sign-up .password-info-area {
        bottom: 180px;
    }
    .section-login .login-info .inputs .password-info-area .close {
        font-size: 14px;
    }
    .section-login .login-info .inputs .password-info-area div {
        font-size: 10px;
    }
    .section-login .login-info .inputs.new-password .password-info-area {
        left: 151px;
        bottom: 72px;
    }
}

@media screen and (max-width: 600px){
    .section-login .login-info{
        padding-top: 425px;
    }
    .section-login .login-logo, .section-login .login-logo .background {
        height: 375px;
    }
    .section-login .login-info .inputs.verification input {
        width: 60px;
        height: 60px;
        font-size: 22px;
    }
    .section-login .login-info .title span{
        font-size: 34px;
    }
    .section-login .login-info .back-to, .section-login .login-info .title p{
        font-size: 13px;
    }
    .section-login .login-info:not(.back-to) .title {
        margin-top: 30px;
    }
    .section-login .login-info .contract .checkmark:after {
        left: 5px;
        top: 3px;
        width: 3px;
        height: 8px;
    }
    .section-login .login-info .contract .checkmark {
        height: 16px;
        width: 16px;
        margin-right: 6px;
    }
    .section-login .login-info .contract label .text {
        font-size: 13px;
    }
}

@media screen and (max-width: 500px){
    .section-login .login-info .inputs-extra {
        flex-direction: column;
        align-items: start;
        justify-content: start;
    }
    .section-login .login-info .inputs-extra a{
        font-size: 12px;
        padding-left: 5px;
    }
    .section-login .login-info .register {
        margin-top: -35px;
        margin-left: 5px;
        font-size: 12px;
    }
    .section-login .login-info .register label {
        margin-right: 5px;
    }
    .section-login .login-info .register a {
        margin-left: 0px;
    }
    .section-login .login-logo img {
        padding: 40px;
        padding-bottom: 5px;
    }
    .section-login i.darkmode {
        width: 70px;
        height: 70px;
        font-size: 22px;
    }
    .section-login .login-info .inputs:not(.verification) input {
        font-size: 14px;
    }
    .section-login .login-info .inputs.verification input {
        width: 50px;
        height: 50px;
        font-size: 20px;
    }
    .section-login .login-info .inputs label {
        font-size: 14px;
    }
    .section-login .login-info .inputs label span {
        font-size: 12px;
    }
    .section-login .login-info .inputs .password-show-hide {
        font-size: 12px;
        bottom: 50px;
    }
    .section-login .login-info .inputs .password-show-hide i {
        font-size: 11px;
    }
    .section-login .login-info .inputs .password-info {
        font-size: 14px;
        margin-left: 5px;
    }
    .section-login .login-info .inputs .password-info-area {
        left: 95px;
        bottom: 172px;
    }
    .section-login .login-info .inputs.new-password .password-info-area {
        left: 127px;
        bottom: 59px;
    }
    .section-login .login-info button{
        font-size: 16px;
    }
    .section-login .login-info .result-message-area div span {
        font-size: 14px;
    }
    .section-login .login-info .inputs.sign-up .password-show-hide {
        bottom: 152px;
    }
    .section-login .login-info .inputs.sign-up .password-show-hide.first {
        bottom: 256px;
    }
    .section-login .login-info .inputs .password-show-hide.first {
        bottom: 154px;
    }
}

@media screen and (max-width: 450px){
    .section-login .login-info{
        padding-top: 375px;
    }
    .section-login .login-logo, .section-login .login-logo .background {
        height: 325px;
    }
    .section-login .login-info .inputs.verification input {
        width: calc(100% / 7);
        height: 45px;
        font-size: 18px;
    }
    .section-login .login-info:not(.back-to) .title {
        margin-top: 0px;
    }
    .section-login .login-info .inputs.new-password .password-info-area {
        left: 3px;
        bottom: 27px;
    }
    .section-login .login-info .contract .checkmark:after {
        top: 13px;
    }
}

@media screen and (max-width: 400px){
    .section-login .login-info .inputs.sign-up .password-info-area {
        bottom: 140px;
        left: 3px;
    }
}

@media screen and (max-width: 258px){
    .section-login .login-info .contract .checkmark:after {
        top: 23px;
    }
}