/*========================= section-wallet */

.section-wallet{
    background-image: url("../../images/walletsection1.svg");
    background-repeat: no-repeat;
    background-color: var(--global-dark1-color) !important;
    background-size: cover;
    background-position: bottom;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.section-wallet-left {
    padding: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
}

.section-wallet .section-wallet-title {
    color: #b8b8b8;
    font-size: 12px;
}

.section-wallet .section-wallet-total {
    padding: 10px 0px;
    font-size: 22px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    height: 86px;
    max-height: 86px;
}

.section-wallet .section-wallet-total span {
    font-size: 20px;
    margin-left: 7px;
    margin-right: 5px;
    vertical-align: text-top;
}

.section-wallet .section-wallet-total span i {
    margin-left: 4px;
}

.section-wallet .section-wallet-total .sectionwallettotalmenu {
    position: absolute;
    background-color: #fff;
    color: #1c1c1c;
    border-radius: 12px;
    font-size: 14px;
    border: 1px solid #e8e8e8;
    display: none;
    margin-top: 30px;
    margin-left: -40px;
    animation-duration: 1s;
    font-weight: 500;
}

.section-wallet .section-wallet-total .sectionwallettotalmenu div{
    padding: 10px;
    transition: .5s;
    cursor: pointer;
}

.section-wallet .section-wallet-total .sectionwallettotalmenu div:first-child{
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.section-wallet .section-wallet-total .sectionwallettotalmenu div:last-child{
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.section-wallet .section-wallet-total .sectionwallettotalmenu div:hover{
    background-color: #aaaaaa50;
}

.section-wallet .section-wallet-btc {
    font-size: 14px;
}

.section-wallet .section-wallet-btc span {
    color: #b8b8b8;
    font-size: 12px;
    margin-left: 5px;
}

.section-wallet-right {
    width: 125px;
    margin-right: 25px;
    position: relative;
    margin-top: -13px;
    margin-bottom: 0px;
}

.section-wallet .show-all-count {
    position: absolute;
    color: #fff;
    top: 10px;
    right: 10px;
    cursor: pointer;
    transition: .4s;
}

.section-wallet .show-all-count:hover {
    opacity: .8;
}

/*========================= section-walletlist-dialog */

.section-walletlist-dialog{
    width: 600px;
}

.section-walletlist-dialog i.fa-solid.fa-caret-up {
    padding-top: 5px;
}

.section-walletlist-dialog .p-dialog-header {
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
}

.section-walletlist-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
}

.section-walletlist-dialog.transfer .p-dialog-content:last-of-type {
    padding: 0px;
}

.section-walletlist-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none !important;
}

.section-walletlist-dialog.p-dialog .p-dialog-header-icons {
    z-index: 9;
}

.section-walletlist-dialog-back-to {
    position: absolute;
    top: 25px;
    z-index: 9;
    left: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: #6c757d;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: .4s;
    cursor: pointer;
}

.section-walletlist-dialog-back-to:hover {
    color: #495057;
    background: #e9ecef;
}

.section-walletlist-dialog-header {
    position: absolute;
    top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 48px);
    z-index: 0;
}

.section-walletlist-dialog-header.with-back-to {
    margin-left: 25px;
}

.section-walletlist-dialog-header img {
    width: 25px;
    height: 25px;
}

.section-walletlist-dialog-header label {
    font-size: 20px;
    font-weight: 600;
    margin: 0px 10px;
}

.section-walletlist-dialog-header span {
    font-size: 16px;
    font-weight: 500;
    color: #888;
    padding-top: 4px;
}

.section-walletlist-dialog-body .count {
    font-size: 18px;
    font-weight: bold;
    padding: 20px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section-walletlist-dialog-body .count label div {
    font-size: 17px;
    padding-right: 3px;
    display: inline-flex;
}

.section-walletlist-dialog-body .price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 5px;
    border-top: 2px solid #eee;
}

.section-walletlist-dialog-body .price span {
    font-weight: 500;
    color: #666;
}

.section-walletlist-dialog-body .price label {
    font-weight: 500;
}

.section-walletlist-dialog-body .transaction-history {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #eee;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: .4s;
}

.section-walletlist-dialog-body .transaction-history:hover {
    background-color: #ddd;
}

.section-walletlist-dialog-body .transaction-history i {
    font-size: 12px;
    padding-top: 3px;
}

.section-walletlist-dialog-body .transaction-history div i {
    font-size: 16px;
    margin-right: 10px;
}

.section-walletlist-dialog-body .currencies {
    margin-top: 25px;
}

.section-walletlist-dialog-body .currencies .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

.section-walletlist-dialog-body .currencies .items .item {
    width: 32.5%;
    display: inline-block;
    margin-bottom: 1%;
    margin-right: 1.25%;
    background-color: #eee;
    text-decoration: none;
    border-radius: 5px;
    padding: 15px;
    font-size: 14px;
    font-weight: 600;
    transition: .4s;
}

.section-walletlist-dialog-body .currencies .items .item:hover {
    background-color: #ddd;
}

.section-walletlist-dialog-body .currencies .items .item:nth-child(3n) {
    margin-right: 0px;
}

.section-walletlist-dialog-body .currencies .items .item label {
    cursor: pointer;
}

.section-walletlist-dialog-body .currencies .items .item .item-title {
    color: #666;
    margin-bottom: 10px;
}

.section-walletlist-dialog-body .currencies .items .item .item-title span {
    font-size: 12px;
}

.section-walletlist-dialog-body .currencies .items .item .item-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
}

.section-walletlist-dialog-body .currencies .items .item .item-info span.success {
    color: var(--global-success-color);
}

.section-walletlist-dialog-body .currencies .items .item .item-info span.danger {
    color: var(--global-danger-color);
}

.section-walletlist-dialog-body .button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
}

.section-walletlist-dialog-body .button div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.section-walletlist-dialog-body .button div div {
    min-width: 350px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.section-walletlist-dialog-body .button .button-element {
    text-decoration: none;
    margin-top: 10px;
    padding: 15px;
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    cursor: pointer;
    transition: .4s;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    color: #333;
    width: 150px;
    text-align: center;
}

.section-walletlist-dialog-body .button .button-element:hover {
    border-color: #aaa;
}

.section-walletlist-dialog-body .button .button-element i {
    margin-right: 10px;
}

.section-walletlist-dialog-transfer .inner-title {
    padding: 0px 30px;
    font-weight: bold;
    font-size: 20px;
}

.section-walletlist-dialog-transfer .info {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 25px;
    padding: 0px 30px;
}

.section-walletlist-dialog-transfer .transfer1 {
    background-color: #eee;
    border-radius: 5px;
    padding: 15px 20px;
    margin: 0px 30px;
}

.section-walletlist-dialog-transfer .transfer1-element {
    display: flex;
    align-items: center;
}

.section-walletlist-dialog-transfer .transfer1-element-header {
    width: 130px;
    font-size: 19px;
}

.section-walletlist-dialog-transfer .transfer1-element-header i {
    font-size: 16px;
    margin-right: 10px;
}

.section-walletlist-dialog-transfer .transfer1-element-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    padding-right: 5px;
    position: relative;
}

.section-walletlist-dialog-transfer .transfer1-element-inner i {
    padding-left: 15px;
}

.section-walletlist-dialog-transfer .transfer1-element-select {
    position: absolute;
    background-color: #fff;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    width: 103%;
    top: 35px;
    margin-left: -4px;
    border-radius: 5px;
    padding: 15px 10px;
    box-shadow: 0px 0px 10px #0000003b;
    animation-name: open-animation;
    animation-duration: .5s;
    z-index: 1;
}

.section-walletlist-dialog-transfer .transfer1-element-select span {
    cursor: pointer;
    padding: 5px 10px;
    margin-bottom: 15px;
    transition: .4s;
}

.section-walletlist-dialog-transfer .transfer1-element-select span:last-child {
    margin: 0px;
}

.section-walletlist-dialog-transfer .transfer1-element-select span:hover, .section-walletlist-dialog-transfer .transfer1-element-select span.active {
    color: #e7a458;
}

.section-walletlist-dialog-transfer .transfer1-element2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 2px;
    padding-right: 6px;
}

.section-walletlist-dialog-transfer .transfer1-element2 i:first-child {
    color: #888;
}

.section-walletlist-dialog-transfer .transfer1-element2 i.change {
    color: #e7a458d4;
    cursor: pointer;
    transition: .4s;
}

.section-walletlist-dialog-transfer .transfer1-element2 i.change:hover{
    color: #de8f36;
}

.section-walletlist-dialog-transfer .transfer2 {
    margin-top: 35px;
    padding: 30px;
    border-top: 2px solid #eee;
    position: relative;
}

.section-walletlist-dialog-transfer .transfer2 label {
    font-weight: 500;
    font-size: 17px;
    color: #666;
}

.section-walletlist-dialog-transfer .transfer2 .coin-info {
    margin-top: 5px;
    margin-bottom: 25px;
    width: 100%;
    background-color: #ddd;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: start;
    min-height: 54px;
}

.section-walletlist-dialog-transfer .transfer2 .coin-info img {
    width: 25px;
    height: 25px;
}

.section-walletlist-dialog-transfer .transfer2 .coin-info .info1 {
    margin: 0px 10px;
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 2px;
}

.section-walletlist-dialog-transfer .transfer2 .coin-info .info2 {
    font-weight: 500;
    font-size: 16px;
    color: #777;
    padding-top: 1px;
}

.section-walletlist-dialog-transfer .transfer2 .amount-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section-walletlist-dialog-transfer .transfer2 .amount-info {
    display: flex;
    align-items: center;
}

.section-walletlist-dialog-transfer .transfer2 input {
    width: 100%;
    border: 0px;
    background-color: #eee;
    border-radius: 5px;
    padding: 15px;
    padding-right: 65px;
    outline: none;
    font-weight: 500;
    margin-top: 5px;
}

.section-walletlist-dialog-transfer .transfer2 .amount-max-button {
    position: absolute;
    right: 45px;
    margin-top: -39px;
    font-weight: 500;
    color: #777;
    transition: .4s;
    cursor: pointer;
}

.section-walletlist-dialog-transfer .transfer2 .amount-max-button.active, .section-walletlist-dialog-transfer .transfer2 .amount-max-button:hover{
    color: #e7a458;
}

.section-walletlist-dialog-transfer .transfer2 .credit-info {
    margin: 25px 0px;
    margin-bottom: 40px;
}

.section-walletlist-dialog-transfer .transfer2 .credit-info .coin-info {
    color: #ccc;
    font-weight: 600;
}

body:not(.dark) .section-walletlist-dialog-transfer .transfer2 .credit-info .coin-info {
    color: #444;
}

.section-walletlist-dialog-transfer .transfer2 .confirm-button {
    display: flex;
    justify-content: end;
    align-items: start;
    margin-top: 25px;
}

.section-walletlist-dialog-transfer .transfer2 .confirm-button.campaign {
    justify-content: space-between;
    align-items: center;
}

.section-walletlist-dialog-transfer .transfer2 .confirm-button.campaign .campaign-info {
    font-size: 13px;
    font-weight: 600;
    padding: 0px 5px;
    max-width: 350px;
}

.section-walletlist-dialog-transfer .transfer2 .confirm-button.campaign .campaign-info.success {
    color: var(--global-success-color);
}

.section-walletlist-dialog-transfer .transfer2 .confirm-button.campaign .campaign-info.danger {
    color: var(--global-danger-color);
}

.section-walletlist-dialog-transfer .transfer2 .confirm-button button {
    border: 0px;
    background-color: #ddd;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    color: #aaa;
    transition: .4s;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 175px;
}

.section-walletlist-dialog-transfer .transfer2 .confirm-button button div {
    width: 46px !important;
    height: 46px !important;
}

.section-walletlist-dialog-transfer .transfer2 .confirm-button button.active {
    background-color: var(--global-success-color);
    color: #eee;
    cursor: pointer;
}

.section-walletlist-dialog-transfer .transfer2 .confirm-button button.active:hover {
    background-color: var(--global-success-hover-color);
}

.section-walletlist-dialog-transfer .transfer2 .amount-info div {
    display: flex;
    align-items: center;
    justify-content: start;
}

.section-walletlist-dialog-transfer .transfer2 .amount-info div div {
    font-weight: 500;
}

.section-walletlist-dialog-transfer .transfer2 .amount-info span {
    font-weight: 400;
    color: #777;
    margin-left: 5px;
}

.section-walletlist-dialog-transfer .transfer2 .amount-info span:nth-child(2) {
    margin: 0px 5px;
}

.section-walletlist-dialog-transaction-history {
    padding: 0px 30px;
    height: 1005px;
}

.section-walletlist-dialog-transaction-history .loading div div {
    height: 27px !important;
    width: 50px !important;
    display: flex;
    align-items: center;
}

.section-walletlist-dialog-transaction-history .inner-title {
    font-weight: bold;
    font-size: 20px;
}

.section-walletlist-dialog-transaction-history .tab-area {
    margin: 25px 0px;
    display: flex;
    justify-content: start;
    align-items: start;
    font-weight: 600;
}

.section-walletlist-dialog-transaction-history .tab-area div {
    margin-right: 25px;
    padding-bottom: 5px;
    cursor: pointer;
}

.section-walletlist-dialog-transaction-history .tab-area div.active {
    border-bottom: 3px solid var(--global-yellow-color);
}

.section-walletlist-dialog-transaction-history .info-area {
    padding-bottom: 25px;
}

.section-walletlist-dialog-transaction-history .info-area .items {
    height: 865px;
    overflow: auto;
    padding-right: 5px;
}

.section-walletlist-dialog-transaction-history .info-area .items .item {
    margin: 10px 0px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    cursor: pointer;
    transition: .4s;
    border-radius: 5px;
}

.section-walletlist-dialog-transaction-history .info-area .items .item:hover {
    background-color: #0000000f;
}

.section-walletlist-dialog-transaction-history .info-area .items .item:last-child {
    margin-bottom: 0px;
}

.section-walletlist-dialog-transaction-history .info-area .items .item div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.section-walletlist-dialog-transaction-history .info-area .items .item div:first-child {
    margin-bottom: 10px;
}

.section-walletlist-dialog-transaction-history .info-area .items .item div label {
    font-weight: 600;
    cursor: pointer;
}

.section-walletlist-dialog-transaction-history .info-area .items .item div span {
    font-size: 14px;
    color: #555;
}

.section-walletlist-dialog-transaction-history .info-area .items .item div span:last-child {
    font-weight: 600;
}

.section-walletlist-dialog-transaction-history .info-area .items .item div span i {
    margin-right: 7px;
}

.section-walletlist-dialog-transaction-history .info-area .items .item div span.approved i {
    color: var(--verification-approved-color);
}

.section-walletlist-dialog-transaction-history .info-area .items .item div span.pending i {
    color: var(--verification-pending-color);
}

.section-walletlist-dialog-transaction-history .info-area .items .item div span.denied i {
    color: var(--verification-denied-color);
}

.section-walletlist-dialog-transaction-history .info-area .items .separator {
    width: 100%;
    background-color: #eee;
    height: 2px;
}

.section-walletlist-dialog-transaction-history .info-area .items .more-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    font-weight: 600;
    font-size: 14px;
    opacity: .7;
    transition: .4s;
    cursor: pointer;
}

.section-walletlist-dialog-transaction-history .info-area .items .more-button:hover {
    opacity: 1;
}

.section-walletlist-dialog-transaction-history .info-area .items .more-button i {
    padding-top: 3px;
    padding-right: 7px;
}

.section-walletlist-dialog-transaction-history .info-area .no-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 0px;
    opacity: .6;
}

.section-walletlist-dialog-transaction-history .info-area .no-item i {
    font-size: 24px;
    margin-bottom: 15px;
}

.section-walletlist-dialog-transaction-history .transaction-detail {
    padding: 25px 0px;
}

.section-walletlist-dialog-transaction-history .transaction-detail .back-to-main {
    position: absolute;
    top: 16px;
    margin-left: -14px;
    width: 50px;
    height: 50px;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    overflow: hidden;
}

.section-walletlist-dialog-transaction-history .transaction-detail .back-to {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    color: #6c757d;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: .4s;
}

.section-walletlist-dialog-transaction-history .transaction-detail .back-to:hover {
    color: #495057;
    background: #e9ecef;
}

.section-walletlist-dialog-transaction-history .transaction-detail .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.section-walletlist-dialog-transaction-history .transaction-detail .top p {
    font-weight: 600;
    font-size: 16px;
    margin: 0px;
    color: #555;
}

.section-walletlist-dialog-transaction-history .transaction-detail .top label {
    font-weight: 600;
    font-size: 28px;
    margin: 15px 0px;
}

.section-walletlist-dialog-transaction-history .transaction-detail .top label div {
    font-size: 16px;
    display: inline-block;
    margin-left: 7px;
    vertical-align: middle;
}

.section-walletlist-dialog-transaction-history .transaction-detail .top span {
    font-weight: 600;
}

.section-walletlist-dialog-transaction-history .transaction-detail .top span.approved {
    color: var(--verification-approved-color);
}

.section-walletlist-dialog-transaction-history .transaction-detail .top span.pending {
    color: var(--verification-pending-color);
}

.section-walletlist-dialog-transaction-history .transaction-detail .top span.denied {
    color: var(--verification-denied-color);
}

.section-walletlist-dialog-transaction-history .transaction-detail .top span i {
    margin-right: 7px;
}

.section-walletlist-dialog-transaction-history .transaction-detail .bottom {
    background-color: #eee;
    margin-top: 50px;
    border-radius: 5px;
}

.section-walletlist-dialog-transaction-history .transaction-detail .bottom .items {
    width: 100%;
}

.section-walletlist-dialog-transaction-history .transaction-detail .bottom .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-weight: 600;
}

.section-walletlist-dialog-transaction-history .transaction-detail .bottom .item label {
    color: #777;
}

/*========================= section-walletlist */

.section-walletlist {
    height: 600px;
}

.section-walletlist-table .MuiTableContainer-root {
    max-height: max-content;
    height: 600px;
}

.section-walletlist-table .MuiPaper-root {
    box-shadow: none;
    background-color: transparent !important;
    color: inherit;
}

.section-walletlist-table .MuiToolbar-root, .section-walletlist-table table thead, .section-walletlist-table table tfoot {
    display: none;
}

.section-walletlist-table table tr {
    background-color: transparent !important;
}

.section-walletlist-table table td {
    padding: 0px;
    width: 100%;
    display: block;
    font-family: inherit;
    border-bottom: 0px;
    background-color: transparent !important;
    color: inherit;
    transition: none;
}

.section-walletlist .no-balance {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 120px 0px;
}

.section-walletlist .no-balance i {
    font-size: 50px;
    color: #ccc;
}

.section-walletlist .no-balance span {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 50px;
    color: #ccc;
}

.section-walletlist .no-balance a {
    width: 200px;
    text-align: center;
    padding: 8px 0px;
    border-radius: 5px;
    background-color: #666;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: .4s;
    text-decoration: none;
}

.section-walletlist .no-balance a:hover {
    background-color: #777;
}

.section-walletlist-element {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #ddd;
    transition: .2s;
}

.section-walletlist-element:hover{
    background-color: #eeeeeea6;
}

.section-walletlist-left {
    display: flex;
    align-items: center;
}

.section-walletlist-left img {
    width: 40px;
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 7px;
    padding: 8px;
}

.section-walletlist-left div {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-left: 10px;
}

.section-walletlist-left label {
    font-weight: bold;
    font-size: 15px;
}

.section-walletlist-left span {
    color: #888;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 1px;
}

.section-walletlist-right {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
}

.section-walletlist-right label {
    font-weight: bold;
    font-size: 14px;
}

.section-walletlist-right span {
    color: #888;
    font-size: 13px;
    padding: 4px 0px;
}

/*========================= section-centertabs */

.section-centertabs {
    height: 875px;
}

.dashboard-sections .single-section .section-centertabs {
    height: auto;
}

.dashboard-sections .single-section .section-centertabs table tr:last-child td {
    border-bottom: 0px;
}

.section-centertabs-tabs {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 20px 20px 5px 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.section-centertabs-tabs div {
    padding: 10px 20px;
    cursor: pointer;
    margin-right: 20px;
    font-weight: 500;
    border-radius: 5px;
    transition: .4s;
}

.section-centertabs-tabs div:last-child{
    margin-right: 0px;
}

.section-centertabs-tabs div.active, .section-centertabs-tabs div:hover {
    background-color: #fbc92e1f;
    color: #ffc100;
}

.section-centertabs-tabs div i {
    margin-right: 10px;
}

/*========================= section-currencystatus */

.section-currencystatus{
    background-color: #fff;
}

.section-currencystatus-dialog{
    width: 600px;
}

.section-currencystatus-dialog i.fa-solid.fa-caret-up {
    padding-top: 5px;
}

.section-currencystatus-dialog .p-dialog-header {
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
}

.section-currencystatus-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
}

.section-currencystatus-dialog.transfer .p-dialog-content:last-of-type {
    padding: 0px;
}

.section-currencystatus-dialog .p-dialog-header .p-dialog-header-icon:focus{
    box-shadow: none !important;
}

.section-currencystatus-dialog label {
    font-weight: 600;
    font-size: 16px;
}

.section-currencystatus-dialog .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

.section-currencystatus-dialog .buttons a {
    height: 44px;
    box-shadow: none !important;
    background-color: transparent;
    color: #333;
    border: 2px solid #777;
    z-index: 1;
    position: relative;
    letter-spacing: normal;
    font-size: 18px;
    font-weight: 600;
    font-family: inherit !important;
    padding: 0px;
    border-radius: 6px;
    width: 49%;
    cursor: pointer;
    text-decoration: none;
    transition: .5s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-currencystatus-dialog .buttons a:hover {
    background-color: #77777725;
}

.section-currencystatus .currencyStatusLoading {
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-currencystatus .MuiTableContainer-root {
    overflow: auto;
    height: 748px;
    max-height: max-content;
}

.section-currencystatus .MuiTableContainer-root table tr:last-child td {
    border-bottom: 0px;
}

.section-currencystatus .MuiPaper-root {
    border: 0px;
    box-shadow: none;
}

.section-currencystatus .MuiToolbar-root:last-child {
    display: none;
}

.section-currencystatus thead tr.MuiTableRow-root {
    box-shadow: none;
}

.section-currencystatus thead tr.MuiTableRow-root th {
    padding-right: 0px;
}

.section-currencystatus-element-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #000;
    font-family: 'Manrope' !important;
    font-size: 16px;
    width: 100%;
}

.section-currencystatus-element-link .innerlink {
    text-decoration: none;
    color: #333;
    display: flex;
}

.section-currencystatus-element-link .innerlink.chart {
    justify-content: center;
    width: 100%;
}

.section-currencystatus-element-name {
    display: flex;
    justify-content: start;
    align-items: center;
    min-width: 225px;
}

.section-currencystatus-element-name .star-icon {
    font-size: 20px;
    color: #ccc;
    transition: .4s;
    cursor: pointer;
}

.section-currencystatus-element-name .star-icon:hover{
    color: #000;
}

.section-currencystatus-element-name .star-icon.active{
    color: #000;
}

.section-currencystatus-element-name img {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 10px;
}

.section-currencystatus-element-name .nametext {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section-currencystatus-element-name .nametext label{
    font-weight: bold;
}

.section-currencystatus-element-name .nametext span{
    font-weight: bold;
    font-size: 13px;
    color: #888;
    display: flex;
    align-items: baseline;
    height: 37px;
    margin-top: -18px;
    letter-spacing: 0.5px;
}

.section-currencystatus-element-name .nametext span p {
    font-size: 16px;
    color: #333;
}

.section-currencystatus-element-chart{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
}

.section-currencystatus-element-chart-inner {
    height: 103px;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.section-currencystatus-element-chart-inner .currencyChartLoading div div, .section-currencystatus-element-chart-inner .currencyChartLoading svg {
    width: 65px !important;
    height: 65px !important;
}

.section-currencystatus-element-chart-inner div div#chart{
    width: 90%;
}

.section-currencystatus-element-chart-inner .ReactApexChart-main{
    min-height: 0px !important;
    height: 46px;
    margin-top: -68px;
}

.section-currencystatus-element-chart-inner .ReactApexChart-main .apexcharts-xaxis-tick, .section-currencystatus-element-chart-inner .ReactApexChart-main .apexcharts-xaxistooltip, .section-currencystatus-element-chart-inner .ReactApexChart-main .apexcharts-grid-borders {
    display: none;
}

.section-currencystatus-element-price {
    font-weight: bold;
    color: #333;
}

.section-currencystatus-element-change {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-currencystatus-element-change label {
    width: 115px;
}

.section-currencystatus-element-change div {
    width: 80px;
    padding: 5px 0px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-currencystatus-element-change div.success{
    background-color: var(--global-success-color);
    color: #fff;
}

.section-currencystatus-element-change div.danger{
    background-color: var(--global-danger-color);
    color: #fff;
}

/*========================= section-quickactions */

.section-quickactions {
    padding: 20px;
    width: 100%;
    margin-right: 8px;
    background-color: transparent;
}

.section-quickactions-title {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 20px;
}

.section-quickactions-image {
    background-image: url("../../images/quickactionssection1.svg");
    background-repeat: no-repeat;
    background-color: var(--global-dark1-color) !important;
    background-size: cover;
    background-position: bottom;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 128px;
}

.section-quickactions-image:not(.campaign) {
    cursor: pointer;
}

.section-quickactions-image-left {
    padding: 30px 20px;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    max-height: 127px;
}

.section-quickactions-image-right {
    width: 125px;
    position: relative;
    margin-top: 3px;
    margin-bottom: -10px;
}

.section-quickactions-image .campaign-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.section-quickactions-image .campaign-area .section-quickactions-image-left {
    cursor: default;
}

.section-quickactions-image .campaign-area .section-quickactions-image-right {
    color: #fff;
    border: 2px solid #777;
    border-radius: 10px;
    background-color: #97979725;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0px 15px;
    padding: 10px;
    width: auto;
    max-width: 150px;
    font-weight: 600;
    cursor: pointer;
    transition: .4s;
    text-decoration: none;
}

.section-quickactions-image .campaign-area .section-quickactions-image-right:hover {
    background-color: #97979740;
}

.section-quickactions-image .campaign-area i {
    color: #fff;
    font-size: 24px;
    margin-right: 15px;
}

.section-quickactions-buttons {
    grid-gap: 16px 8px;
    display: grid;
    gap: 16px 8px;
    grid-template-columns: repeat(4,minmax(0,1fr));
    margin-top: 30px;
}

.section-quickactions-button1, .section-quickactions-button2 {
    padding: 15px;
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    cursor: pointer;
    transition: .4s;
    font-size: 13px;
    background-color: #fff;
}

.section-quickactions-button1:hover, .section-quickactions-button2:hover {
    border-color: #aaa;
}

.section-quickactions-button1 a, .section-quickactions-button2 {
    text-decoration: none;
    color: inherit;
}

.section-quickactions-button1 div i, .section-quickactions-button1 a i, .section-quickactions-button2 i {
    font-size: 16px;
}

.section-quickactions-button1 {
    align-items: center;
    display: flex;
    grid-column: span 2;
    justify-content: space-between;
}

.section-quickactions-button1 div i, .section-quickactions-button1 a i {
    margin-right: 7px;
}

.section-quickactions-button2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section-quickactions-button2 i {
    margin-bottom: 5px;
}

/*========================= koyu tema düzenlemeleri */

body.dark .section-wallet .section-wallet-total .sectionwallettotalmenu {
    background-color: #333;
    border-color: #555;
    color: #eee;
}

body.dark .section-wallet .section-wallet-total .sectionwallettotalmenu div:hover {
    background-color: #444;
}

body.dark .section-walletlist-dialog-body .price {
    border-color: #444;
}

body.dark .section-walletlist-dialog-body .price span {
    color: #aaa;
}

body.dark .section-walletlist-dialog-transfer .transfer1, body.dark .section-walletlist-dialog-transfer .transfer2 input{
    background-color: #444;
}

body.dark .section-walletlist-dialog-transfer .transfer2 .coin-info{
    background-color: #2b2b2b;
}

body.dark .section-walletlist-dialog-transfer .transfer1-element-select{
    background-color: #555;
}

body.dark .section-walletlist-dialog-transfer .transfer1-element2 i.change:hover{
    color: #daa66c;
}

body.dark .section-walletlist-dialog-transfer .transfer2 {
    border-color: #444;
}

body.dark .section-walletlist-dialog-transfer .transfer2 .coin-info .info2, body.dark .section-walletlist-dialog-transfer .transfer2 label{
    color: #ccc;
}

body.dark .section-walletlist-dialog-transfer .transfer2 .amount-info span, body.dark .section-walletlist-dialog-transfer .transfer2 .amount-max-button{
    color: #999;
}

body.dark .section-walletlist-dialog-transfer .transfer2 .amount-max-button.active, body.dark .section-walletlist-dialog-transfer .transfer2 .amount-max-button:hover{
    color: #e7a458;
}

body.dark .section-walletlist-dialog-transfer .transfer2 .amount-info div div, body.dark .section-walletlist-dialog-transfer .transfer2 input {
    color: #ddd;
}

body.dark .section-walletlist-dialog-transfer .transfer2 .confirm-button button {
    background-color: #444;
    color: #888;
}

body.dark .section-walletlist-dialog-transfer .transfer2 .confirm-button button.active {
    background-color: #179763;
    color: #eee;
}

body.dark .section-walletlist-dialog-transfer .transfer2 .confirm-button button.active:hover {
    background-color: var(--global-success-color);
}

body.dark .section-walletlist-dialog-body .button .button-element {
    border-color: #777;
    color: #fff;
}

body.dark .section-walletlist-dialog-body .button .button-element:hover {
    border-color: #aaa;
}

body.dark .section-walletlist-dialog-body .transaction-history, body.dark .section-walletlist-dialog-body .currencies .items .item {
    background-color: #4c4c4c;
}

body.dark .section-walletlist-dialog-body .transaction-history:hover, body.dark .section-walletlist-dialog-body .currencies .items .item:hover {
    background-color: #555;
}

body.dark .section-walletlist-dialog-body .currencies .items .item .item-title {
    color: #ccc;
}

body.dark .section-walletlist-dialog-body .currencies .items .item .item-info {
    color: #fff;
}

body.dark .section-walletlist-dialog-body .currencies .items .item .item-info span.success {
    color: var(--global-success-color);
}

body.dark .section-walletlist-dialog-body .currencies .items .item .item-info span.danger {
    color: #ed3d3d;
}

body.dark .section-walletlist-dialog-transaction-history .info-area .items .item div span {
    color: #ddd;
}

body.dark .section-walletlist-dialog-transaction-history .info-area .items .separator {
    background-color: #555;
}

body.dark .section-walletlist-dialog-transaction-history .info-area .items .item:hover {
    background-color: #0000001f;
}

body.dark .section-walletlist-dialog-transaction-history .transaction-detail .top p {
    color: #bbb;
}

body.dark .section-walletlist-dialog-transaction-history .transaction-detail .bottom {
    background-color: #2d2d2d;
}

body.dark .section-walletlist-dialog-transaction-history .transaction-detail .bottom .item label {
    color: #bbb;
}

body.dark .section-walletlist-dialog-transaction-history .transaction-detail .back-to-main {
    background-color: #333;
}

body.dark .section-walletlist-dialog-back-to:hover, body.dark .section-walletlist-dialog-transaction-history .transaction-detail .back-to:hover {
    color: #ccc;
    background-color: #555;
}

body.dark .section-walletlist .no-balance i, body.dark .section-walletlist .no-balance span {
    color: #555;
}

body.dark .section-walletlist .no-balance a {
    background-color: #3b3b3b;
    color: #bbb;
}

body.dark .section-walletlist .no-balance a:hover {
    background-color: #2f2f2f;
}

body.dark .section-walletlist-element {
    border-color: #555;
}

body.dark .section-walletlist-element:hover{
    background-color: #44444475;
}

body.dark .section-walletlist-element img {
    border-color: #aaaaaa00;
    background-color: #505050bd;
}

body.dark .section-centertabs-tabs div.active, body.dark .section-centertabs-tabs div:hover {
    background-color: #f59e0b0a;
    color: var(--global-yellow-color);
}

body.dark .section-currencystatus-dialog .buttons a {
    color: #ddd;
}

body.dark .section-currencystatus .apexcharts-tooltip.apexcharts-theme-light {
    color: #eee;
    background-color: #2b2b2b;
    border-color: #555;
}

body.dark .section-currencystatus p, body.dark .section-currencystatus-element-price, body.dark .section-currencystatus-element-link, body.dark .section-currencystatus-element-link .innerlink, body.dark .section-currencystatus .MuiSelect-select{
    color: #ccc;
}

body.dark .section-currencystatus .MuiPaper-root, body.dark .section-currencystatus .currencyStatusLoading {
    background-color: #212121;
}

body.dark .section-currencystatus .MuiToolbar-root, body.dark .section-currencystatus table.MuiTable-root {
    background-color: #212121;
}

body.dark .section-currencystatus .MuiToolbar-root button {
    color: #ccc;
}

body.dark .section-currencystatus .MuiTableRow-root svg, body.dark .section-currencystatus .MuiToolbar-root svg {
    fill: #aaa;
}

body.dark .section-currencystatus tr.MuiTableRow-root{
    background-color: #212121;
}

body.dark .section-currencystatus tr.MuiTableRow-root td, body.dark .section-currencystatus tr.MuiTableRow-root th{
    color: #ddd;
    border-color: #656565;
}

body.dark .section-currencystatus tr.MuiTableRow-root{
    color: #ddd;
    border-color: #555;
}

body.dark .section-currencystatus tbody tr.MuiTableRow-root:hover, body.dark .section-currencystatus tbody tr.MuiTableRow-root:hover td{
    background-color: #44444424;
}

body.dark .section-currencystatus-element{
    border-color: #333;
    color: #ddd;
}

body.dark .section-currencystatus-element-name .star-icon{
    color: #777;
}

body.dark .section-currencystatus-element-name .star-icon:hover, body.dark .section-currencystatus-element-name .star-icon.active{
    color: var(--global-yellow-color);
}

body.dark .section-currencystatus-element-name img{
    border-color: #aaaaaa00;
    background-color: #505050bd;
}

body.dark .section-currencystatus-element-center-inner .ReactApexChart-main .apexcharts-tooltip{
    background: #333;
    border-color: #555;
}

body.dark .section-currencystatus-sorting-element{
    color: #aaa;
}

body.dark .section-currencystatus-sorting-element div i{
    color: #777;
}

body.dark .section-quickactions-image {
    background-color: var(--global-dark2-color) !important;
}

body.dark .section-quickactions-button1, body.dark .section-quickactions-button2 {
    color: #e8e8e8;
    background-color: transparent;
    border-color: #444;
}

body.dark .section-quickactions-button1:hover, body.dark .section-quickactions-button2:hover {
    border-color: #888;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-height: 1225px){
    .section-walletlist-dialog-transaction-history {
        height: 822px;
    }
    .section-walletlist-dialog-transaction-history .info-area .items {
        height: 685px;
    }
}

@media screen and (max-height: 1000px){
    .section-walletlist-dialog-transaction-history {
        height: 652px;
    }
    .section-walletlist-dialog-transaction-history .info-area .items {
        height: 515px;
    }
}

@media screen and (max-height: 815px){
    .section-walletlist-dialog-transaction-history {
        height: 482px;
    }
    .section-walletlist-dialog-transaction-history .info-area .items {
        height: 345px;
    }
}

@media screen and (max-height: 630px){
    .section-walletlist-dialog-transaction-history {
        height: 307px;
    }
    .section-walletlist-dialog-transaction-history .info-area .items {
        height: 170px;
    }
}

@media screen and (max-height: 630px){
    .section-walletlist-dialog-transaction-history {
        height: auto;
    }
}

@media screen and (max-width: 2100px){
    .section-quickactions-image-left {
        font-size: 18px;
    }
}

@media screen and (max-width: 1800px) and (min-width: 1615px){
    .section-quickactions-image .campaign-area .section-quickactions-image-left {
        padding: 30px 5px;
    }
    .section-quickactions-image .campaign-area .section-quickactions-image-right {
        margin: 0px 5px;
        font-size: 14px;
    }
}

@media screen and (max-width: 1615px){
    .dashboard-sections{
        display: block;
    }
    .dashboard-section1{
        display: inline-block;
        width: calc(50% - 8px);
        margin-right: 8px;
        margin-bottom: 8px;
    }
    .dashboard-section2{
        width: calc(100% - 100px);
        position: absolute;
        margin: 0px;
        padding-bottom: 50px;
    }
    .dashboard-section3{
        display: inline-block;
        width: 50%;
    }
    .section-centertabs{
        height: auto;
        margin-top: 0px !important;
    }
    .section-currencystatus tbody .MuiTableRow-root:last-child td {
        border-bottom: 0px;
    }
}

@media screen and (max-width: 1024px){
    .dashboard-section1{
        width: 100%;
        margin-right: 0px;
    }
    .dashboard-section2{
        width: 100%;
        position: relative;
        padding: 0px;
    }
    .dashboard-section3{
        width: 100%;
    }
    .section-walletlist, .section-walletlist-table .MuiTableContainer-root {
        max-height: 500px !important;
        height: auto !important;
    }
}

@media screen and (max-width: 820px){
    .section-quickactions {
        width: 100%;
    }
    .section-centertabs-tabs {
        padding: 10px 10px 5px 10px;
    }
    .section-centertabs-tabs div {
        font-size: 14px;
    }
}

@media screen and (max-width: 750px){
    .section-currencystatus-sorting{
        margin-right: 8px;
    }
    .section-currencystatus-sorting-element{
        margin-right: -12px;
        font-size: 10px;
    }
    .section-currencystatus-element-name .nametext label, .section-currencystatus-element-name .nametext span p{
        font-size: 14px;
    }
    .section-currencystatus-element-name .nametext span{
        font-size: 11px;
    }
    .section-currencystatus-element-right label {
        width: 85px;
        font-size: 12px;
    }
    .section-currencystatus-element-right div{
        width: 40px;
        font-size: 8px;
        padding: 5px;
    }
    .section-walletlist-dialog{
        width: 95%;
    }
}

@media screen and (max-width: 700px){
    .section-centertabs-tabs div{
        padding: 5px 10px;
    }
}

@media screen and (max-width: 650px){
    .section-currencystatus-element-name .nametext label, .section-currencystatus-element-name .nametext span p{
        font-size: 12px;
    }
    .section-currencystatus-element-name .nametext span{
        font-size: 9px;
        height: 32px;
        margin-top: -14px;
    }
    .section-currencystatus-dialog label {
        font-size: 14px;
    }
    .section-currencystatus-dialog .buttons a {
        height: 36px;
        font-size: 14px;
    }
}

@media screen and (max-width: 630px){
    .section-currencystatus-element-name {
        min-width: max-content;
    }
}

@media screen and (max-width: 600px){
    .section-centertabs-tabs div{
        font-size: 12px;
    }
    .section-walletlist-dialog-body .currencies .items .item .item-info {
        flex-direction: column;
        align-items: start;
    }
}

@media screen and (max-width: 550px){
    .section-currencystatus-sorting-right .section-currencystatus-sorting-element:first-child {
        width: auto;
        position: absolute;
        left: 135px;
    }
    .section-currencystatus-element-right label{
        width: auto;
        position: absolute;
        left: 127px;
        margin-top: 28px;
    }
    .section-quickactions-image-left {
        font-size: 14px;
    }
    .section-quickactions-image .campaign-area .section-quickactions-image-right {
        font-size: 14px;
        margin-left: 0px;
    }
    .section-quickactions-image .campaign-area i {
        font-size: 18px;
        margin-right: 10px;
    }
}

@media screen and (max-width: 500px){
    .section-wallet .section-wallet-total {
        font-size: 16px;
    }
    .section-wallet .section-wallet-total span {
        font-size: 14px;
        vertical-align: text-bottom;
    }
    .section-wallet .section-wallet-btc {
        font-size: 12px;
    }
    .section-walletlist-element {
        padding: 15px;
    }
    .section-walletlist-left img {
        width: 25px;
        height: 25px;
        border-radius: 5px;
        padding: 3px;
    }
    .section-walletlist-left div {
        margin-left: 8px;
    }
    .section-walletlist-left label {
        font-size: 12px;
    }
    .section-walletlist-left span {
        font-size: 9px;
        padding-left: 1px;
        letter-spacing: 0.8px;
    }
    .section-walletlist-right label {
        font-weight: bold;
        font-size: 12px;
    }
    .section-walletlist-right span {
        color: #888;
        font-size: 11px;
        padding: 4px 0px;
    }
    .section-walletlist-dialog-body .button div div{
        flex-direction: column;
        min-width: auto;
        margin: 0px;
    }
    .section-walletlist-dialog-header span {
        font-size: 14px;
        padding-top: 3px;
    }
    .section-walletlist-dialog-header label {
        font-size: 16px;
    }
    .section-walletlist-dialog-body .count {
        font-size: 13px;
    }
    .section-walletlist-dialog-body .count label div {
        font-size: 11px;
    }
    .section-walletlist-dialog-body .price {
        font-size: 12px;
    }
    .section-walletlist-dialog-body .transaction-history, .section-walletlist-dialog-body .transaction-history div i {
        font-size: 12px;
    }
    .section-walletlist-dialog-body .transaction-history i {
        font-size: 10px;
        padding-top: 2px;
    }
    .section-walletlist-dialog-transaction-history .tab-area {
        font-size: 12px;
    }
    .section-walletlist-dialog-transaction-history .info-area .items .item div label {
        font-size: 12px;
    }
    .section-walletlist-dialog-transaction-history .info-area .items .item div span {
        font-size: 12px;
    }
    .section-walletlist-dialog-transaction-history .inner-title {
        font-size: 16px;
    }
    .section-walletlist-dialog-transaction-history .transaction-detail .top p {
        font-size: 14px;
    }
    .section-walletlist-dialog-transaction-history .transaction-detail .top label {
        font-size: 22px;
    }
    .section-walletlist-dialog-transaction-history .transaction-detail .top span {
        font-size: 12px;
    }
    .section-walletlist-dialog-transaction-history .transaction-detail .bottom .item {
        font-size: 12px;
    }
    .section-walletlist-dialog-transaction-history .info-area .no-item {
        font-size: 12px;
    }
    .section-walletlist-dialog-transaction-history .info-area .no-item i {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .section-walletlist-dialog-body .currencies .title {
        font-size: 14px;
    }
    .section-walletlist-dialog-body .currencies .items .item {
        width: 49%;
        margin-bottom: 2%;
        margin-right: 2%;
        font-size: 12px;
        padding: 10px;
    }
    .section-walletlist-dialog-body .currencies .items .item:nth-child(3n) {
        margin-right: 2%;
    }
    .section-walletlist-dialog-body .currencies .items .item:nth-child(2n) {
        margin-right: 0px;
    }
    .section-walletlist-dialog-body .currencies .items .item .item-title span {
        font-size: 10px;
    }
    .section-walletlist-dialog-body .button .button-element {
        font-size: 12px;
    }
    .section-walletlist-dialog-transfer .inner-title {
        font-size: 16px;
    }
    .section-walletlist-dialog-transfer .info {
        font-size: 14px;
    }
    .section-walletlist-dialog-transfer .transfer1-element-header, .section-walletlist-dialog-transfer .transfer1-element-header i, .section-walletlist-dialog-transfer .transfer1-element2, .section-walletlist-dialog-transfer .transfer1-element-inner {
        font-size: 14px;
    }
    .section-walletlist-dialog-transfer .transfer1-element-select {
        padding: 10px;
    }
    .section-walletlist-dialog-transfer .transfer2 label {
        font-size: 15px;
    }
    .section-walletlist-dialog-transfer .transfer2 .coin-info img {
        width: 20px;
        height: 20px;
    }
    .section-walletlist-dialog-transfer .transfer2 .coin-info .info1 {
        font-size: 14px;
        padding-bottom: 0px;
    }
    .section-walletlist-dialog-transfer .transfer2 .coin-info .info2 {
        font-size: 14px;
        padding-top: 0px;
    }
    .section-currencystatus-dialog label {
        font-size: 12px;
    }
    .section-currencystatus-dialog .buttons a {
        height: 32px;
        font-size: 12px;
    }
    .section-currencystatus .MuiTableCell-head {
        padding: 10px;
    }
    .section-currencystatus .MuiButtonBase-root.MuiIconButton-root .MuiSvgIcon-root, .section-currencystatus .Mui-TableHeadCell-Content-Labels .MuiBadge-root .MuiTableSortLabel-icon {
        width: 15px;
        height: 15px;
    }
    .section-currencystatus .Mui-TableHeadCell-Content-Labels .MuiBadge-root .MuiTableSortLabel-icon {
        margin: 0px;
        margin-left: 10px;
    }
    .section-currencystatus .Mui-TableHeadCell-Content-Labels .Mui-TableHeadCell-Content-Wrapper {
        font-size: 11px;
    }
    .section-currencystatus-element-name .star-icon {
        font-size: 12px;
    }
    .section-currencystatus-element-name img {
        width: 25px;
        height: 25px;
        border-radius: 7px;
        padding: 4px;
    }
    .section-currencystatus-element-name .nametext label, .section-currencystatus-element-name .nametext span p {
        font-size: 10px;
    }
    .section-currencystatus-element-name .nametext span {
        font-size: 8px;
        height: 24px;
        margin-top: -13px;
    }
    .section-currencystatus-element-link {
        font-size: 10px;
    }
    .section-currencystatus-element-change div {
        width: 70px;
        padding: 3px 0px;
        font-size: 8px;
    }
    .section-centertabs-tabs div {
        margin-right: 10px;
    }
    .section-centertabs-tabs div:last-child {
        margin-right: 0px;
    }
    .section-quickactions-title {
        font-size: 16px;
    }
    .section-quickactions-image-right {
        width: 100px;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-right: 10px;
    }
    .section-quickactions-button1 div i, .section-quickactions-button1 a i {
        vertical-align: text-bottom;
    }
    .section-quickactions-button1, .section-quickactions-button2 {
        padding: 10px;
        font-size: 11px;
    }
    .section-quickactions-button1 div i, .section-quickactions-button1 a i, .section-quickactions-button2 i {
        font-size: 13px;
    }
    .section-quickactions-button1 {
        padding-left: 15px;
    }
    .section-walletlist-dialog-transfer .transfer2 .amount-info {
        font-size: 10px;
    }
    .section-walletlist-dialog-transfer .transfer2 input {
        font-size: 14px;
    }
    .section-walletlist-dialog-transfer .transfer2 .amount-max-button {
        margin-top: -36px;
        font-size: 14px;
    }
    .section-walletlist-dialog-transfer .transfer2 .confirm-button button {
        font-size: 14px;
        height: 40px;
        width: 160px;
    }
    .section-walletlist-dialog-transfer .transfer2 .coin-info {
        font-size: 14px;
    }
    .section-walletlist-dialog-transfer .transfer2 .confirm-button.campaign .campaign-info {
        font-size: 10px;
    }
    .section-wallet .show-all-count {
        font-size: 14px;
    }
}

@media screen and (max-width: 400px){
    .section-walletlist-dialog-transfer .transfer1-element-header{
        font-size: 15px;
    }
    .section-walletlist-dialog-transfer .transfer1-element-header i {
        font-size: 12px;
    }
    .section-walletlist-dialog-transfer .transfer1-element-inner{
        font-size: 14px;
    }
    .section-walletlist-dialog-transfer .transfer1-element-select{
        top: 30px;
        margin-left: -2px;
    }
    .section-centertabs-tabs div {
        margin-right: 5px;
        font-size: 10px;
    }
    .section-centertabs-tabs div:last-child {
        margin-right: 0px;
    }
    .section-currencystatus .MuiTableCell-head {
        padding: 5px;
    }
    .section-currencystatus .MuiTableCell-root.MuiTableCell-body {
        padding: 16px 5px;
    }
    .section-currencystatus-element-name .star-icon {
        font-size: 10px;
    }
    .section-currencystatus-element-name img {
        width: 20px;
        height: 20px;
        border-radius: 6px;
        padding: 2px;
        margin-right: 5px;
    }
    .section-currencystatus-element-name .nametext label, .section-currencystatus-element-name .nametext span p {
        font-size: 9px;
        margin-bottom: 2px;
    }
    .section-currencystatus-element-name .nametext span {
        height: 22px;
        margin-top: -12px;
    }
    .section-currencystatus-element-link {
        font-size: 9px;
    }
    .section-currencystatus-element-change div {
        width: 70px;
        padding: 3px 0px;
        font-size: 8px;
    }
}

@media screen and (max-width: 350px){
    .section-walletlist-dialog-transfer .transfer1-element-header{
        font-size: 12px;
    }
    .section-walletlist-dialog-transfer .transfer1-element-header i {
        font-size: 11px;
    }
    .section-walletlist-dialog-transfer .transfer1-element-inner{
        font-size: 12px;
    }
    .section-walletlist-dialog-transfer .transfer1-element-select{
        top: 25px;
    }
    .section-walletlist-dialog-transfer .transfer2 .amount-info {
        font-size: 8px;
    }
}

@media screen and (max-width: 300px){
    .section-currencystatus .MuiTableCell-head {
        padding: 2px;
    }
    .section-currencystatus .Mui-TableHeadCell-Content-Labels .Mui-TableHeadCell-Content-Wrapper {
        font-size: 9px;
    }
    .section-currencystatus .MuiButtonBase-root.MuiIconButton-root .MuiSvgIcon-root, .section-currencystatus .Mui-TableHeadCell-Content-Labels .MuiBadge-root .MuiTableSortLabel-icon {
        width: 10px;
        height: 10px;
    }
    .section-walletlist-dialog-transfer .transfer2 .amount-title {
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    .section-walletlist-dialog-transfer .transfer2 .amount-info {
        display: flex;
        align-items: start;
        flex-direction: column;
    }
}
