/*========================= profile-area */

.profile-no-user {
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile-no-user i {
    font-size: 30px;
    opacity: .5;
}

.profile-no-user div {
    margin-top: 15px;
    font-size: 18px;
    opacity: .6;
}

.profile-header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 20px;
}

.profile-header .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% / 7);
    padding-right: 20px;
    overflow-wrap: anywhere;
    text-align: center;
}

.profile-header .item:last-child {
    padding-right: 0px;
}

.profile-header .item label {
    font-weight: 600;
    font-size: 14px;
}

.profile-body {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.profile-body-top, .profile-body-bottom {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
}

.profile-body-center {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
}

.profile-body .profile-body-left {
    width: calc(75% - 8px);
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.profile-body .profile-body-right {
    width: 25%;
}

.profile-future {
    width: 100%;
}

.profile-future .general-values {
    border-bottom: 1px solid #ddd;
    padding: 20px;
    min-height: 224px;
}

.profile-future .general-values:not(.loading) div {
    align-items: center;
    display: flex;
    justify-content: start;
    margin-bottom: 5px;
    width: -webkit-max-content;
    width: max-content;
}

.profile-future .general-values span {
    color: #555;
    font-size: 13px;
    font-weight: 700;
}

.profile-future .general-values label {
    font-size: 17px;
    font-weight: 700;
    margin-left: 5px;
}

.profile-future .general-values .info-icon {
    cursor: pointer;
    margin-left: 10px;
    opacity: .5;
    transition: .4s;
}

.profile-future .general-values .info-icon:hover {
    opacity: .3;
}

.profile-future .general-values .credit-info {
    align-items: start!important;
    -webkit-animation: open-animation .75s;
    animation: open-animation .75s;
    background-color: #eee;
    border-radius: 10px;
    box-shadow: 0 0 10px #aaa;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    height: 128px!important;
    justify-content: start!important;
    margin-left: 47px;
    margin-top: 160px;
    padding: 15px;
    position: absolute;
    width: 250px!important;
}

.profile-future .general-values .credit-info div b {
    margin-left: 5px;
}

.profile-future .total-profit-area {
    display: flex;
    font-size: 22px;
    font-weight: 700;
    justify-content: center;
    padding: 10px 0 0 15px;
    position: absolute;
    width: 100%;
}

.profile-future .total-profit-area label.success {
    color: var(--global-success-color);
}

.profile-future .total-profit-area label.danger {
    color: var(--global-danger-color);
}

.profile-future .back-to-profile-area {
    width: max-content;
    font-size: 14px;
    padding: 18px 0 0 15px;
    position: absolute;
    z-index: 2;
}

.profile-future .back-to-profile-area a {
    text-decoration: none;
    color: inherit;
    transition: .4s;
}

.profile-future .back-to-profile-area a:hover {
    opacity: .8;
}

.profile-future .back-to-profile-area a i {
    margin-right: 8px;
}

.profile-future .table-area {
    min-height: 616px;
}

.profile-future .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-dense {
    background-color: transparent !important;
}

.profile-future .table-area .noPagination .MuiTableContainer-root {
    height: 560px;
}

.profile-future .table-area.with-verify-info .noPagination .MuiTableContainer-root {
    height: 515px;
}

.profile-future .table-area.with-verify-info .verify-info {
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding: 10px;
}

.profile-wallet {
    width: calc(100% / 3);
    margin-top: 20px;
}

.profile-informations {
    width: calc((100% / 1.5) - 8px);
    min-height: 842px;
    padding: 50px;
}

.profile-informations .item {
    margin-bottom: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.profile-informations .item:last-child {
    margin-bottom: 0px;
}

.profile-informations .item.br {
    margin-top: 25px;
}

.profile-informations .item label {
    margin-right: 4px;
    font-size: 14px;
    font-weight: 600;
}

.profile-informations .item span {
    display: flex;
}

.profile-informations .item span img {
    width: 22px !important;
    height: auto !important;
    border-radius: 2px;
    margin-left: 2px;
}

.profile-informations .item .status-icon {
    margin-left: 2px;
    margin-right: 6px;
    text-decoration: none;
    transition: .4s;
}

.profile-informations .item .status-icon.success {
    color: var(--verification-approved-color);
}

.profile-informations .item .status-icon.warning {
    color: var(--verification-pending-color);
}

.profile-informations .item .status-icon.danger {
    color: var(--verification-denied-color);
}

.profile-buttons .status-icon.warning {
    color: var(--global-yellow-color);
}

.profile-informations .item .status-icon.warning:hover, .profile-informations .item .status-icon.danger:hover {
    opacity: .8;
}

.profile-buttons {
    width: 100%;
    min-height: 842px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.profile-buttons .button {
    background-color: #333;
    color: #eee;
    padding: 12px 10px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: bold;
    transition: .4s;
    cursor: pointer;
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    text-decoration: none;
}

.profile-buttons .button:hover {
    background-color: #444;
    color: var(--global-yellow-color);
}

.profile-buttons .button:last-child {
    margin-bottom: 0px;
}

.profile-buttons .button i {
    margin-right: 8px;
}

.profile-buttons .status-icon.warning {
    margin-left: 10px;
}

.profile-body-center .profile-spot-orders {
    width: 100%;
}

.profile-body-center .profile-spot-orders .tabs-section {
    padding: 18px 25px;
    margin-top: 18px;
    border-top: 2px solid #f1f1f1;
    border-bottom: 2px solid #f1f1f1;
}

.profile-body-bottom .profile-history {
    width: 33%;
}

.profile-body-bottom .profile-history .main {
    margin-left: 0px;
    padding: 0px;
}

.profile-body-bottom .profile-history .main .allsections.allsections-border {
    border: 0px;
    margin: 0px;
}

.profile-body-bottom .profile-history .scrollable-section {
    height: 600px;
}

.profile-body-bottom .profile-history .scrollable-section-elements {
    height: 560px;
}

.profile-body-bottom .profile-history .scrollable-section-elements .element .element-area {
    width: 47%;
}

.verification-body {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.verification-body .verification-body-top, .verification-body .verification-body-bottom {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
}

.verification-body .profile-informations {
    width: 25%;
    min-height: 904px;
    padding-right: 10px;
}

.verification-body .verification-steps {
    width: 100%;
    min-height: 250px;
}

.verification-body .verification-steps .verification-steps-title {
    padding: 40px 0px 0px 50px;
    font-weight: bold;
    font-size: 20px;
}

.verification-body .verification-informations {
    width: calc(75% - 8px);
    min-height: 904px;
    padding: 50px;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.verification-body .verification-informations .verification-informations-left, .verification-body .verification-informations .verification-informations-right {
    width: 49%;
    min-height: 803px;
}

.verification-body .verification-informations .verification-informations-right .step-area {
    min-height: 803px;
}

.verification-body .verification-informations .step-area {
    box-shadow: 0px 0px 10px #00000025;
    border-radius: 12px;
    padding: 20px;
}

.verification-body .verification-informations .step-area.verification2 {
    margin-top: 20px;
}

.verification-body .verification-informations .step-area-title {
    display: flex;
    align-items: center;
    justify-content: start;
}

.verification-body .verification-informations .step-area-title div {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    border-radius: 50px;
    font-weight: bold;
    margin-right: 10px;
}

.verification-body .verification-informations .step-area-title label {
    font-weight: bold;
}

.verification-body .verification-informations .step-area-info {
    margin-top: 15px;
    padding-left: 45px;
    font-size: 14px;
}

.verification-body .verification-informations .step-area-info .title-icon, .verification-body .verification-informations .step-area-inner-title .title-icon {
    margin-left: 7px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid;
    border-radius: 5px;
    padding: 2px 5px;
    vertical-align: top;
}

.verification-body .verification-informations .step-area-info .title-icon i, .verification-body .verification-informations .step-area-inner-title .title-icon i {
    font-size: 16px;
    margin-right: 5px;
    padding-top: 1px;
}

.verification-body .verification-informations .step-area-info .approved i, .verification-body .verification-informations .step-area-inner-title .approved i {
    color: var(--verification-approved-color);
}

.verification-body .verification-informations .step-area-info .pending i, .verification-body .verification-informations .step-area-inner-title .pending i {
    color: var(--verification-pending-color);
}

.verification-body .verification-informations .step-area-info .denied i, .verification-body .verification-informations .step-area-inner-title .denied i {
    color: var(--verification-denied-color);
}

.verification-body .verification-informations .step-area-info .approved, .verification-body .verification-informations .step-area-inner-title .approved {
    border-color: #0b811650;
    background-color: #0b811615;
}

.verification-body .verification-informations .step-area-info .pending, .verification-body .verification-informations .step-area-inner-title .pending {
    border-color: #eda80050;
    background-color: #eda80015;
}

.verification-body .verification-informations .step-area-info .denied, .verification-body .verification-informations .step-area-inner-title .denied {
    border-color: #d52f1c50;
    background-color: #d52f1c15;
}

.verification-body .verification-informations .step-area .step-area-inner {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 20px;
    width: 100%;
}

.verification-body .verification-informations .step-area .step-area-inner-main {
    flex-direction: column;
}

.verification-body .verification-informations .step-area .step-area-inner-content {
    box-shadow: 0px 0px 10px #0b811625;
    border-radius: 7px;
    padding: 15px;
    width: calc(80% - 15px);
    overflow: auto;
}

.verification-body .verification-informations .step-area.approved {
    box-shadow: 0px 0px 15px #0b811650;
}

.verification-body .verification-informations .step-area .step-area-inner-content.approved {
    box-shadow: 0px 0px 10px #0b811650;
}

.verification-body .verification-informations .step-area.pending {
    box-shadow: 0px 0px 15px #ffb50060;
}

.verification-body .verification-informations .step-area .step-area-inner-content.pending {
    box-shadow: 0px 0px 10px #ffb50060;
}

.verification-body .verification-informations .step-area.denied {
    box-shadow: 0px 0px 15px #d52f1c50;
}

.verification-body .verification-informations .step-area .step-area-inner-content.denied {
    box-shadow: 0px 0px 10px #d52f1c50;
}

.verification-body .verification-informations .step-area .step-area-inner.step-area-inner-content {
    flex-direction: column;
}

.verification-body .verification-informations .step-area .step-area-inner-content.no-buttons {
    width: 100%;
}

.verification-body .verification-informations .step-area.verification2 .step-area-inner.info .step-area-inner-content, .verification-body .verification-informations .step-area.verification2 .step-area-inner.info .step-area-inner-buttons {
    min-height: 431px;
}

.verification-body .verification-informations .step-area.verification2 .step-area-inner.phone .step-area-inner-content, .verification-body .verification-informations .step-area.verification2 .step-area-inner.phone .step-area-inner-buttons {
    min-height: 123px;
}

.verification-body .verification-informations .step-area.verification3 .step-area-inner.image1 .step-area-inner-content, .verification-body .verification-informations .step-area.verification3 .step-area-inner.image1 .step-area-inner-buttons {
    min-height: 136px;
}

.verification-body .verification-informations .step-area .step-area-inner-title {
    font-weight: bold;
    padding-bottom: 20px;
}

.verification-body .verification-informations .step-area .step-area-inner label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-top: 7px;
}

.verification-body .verification-informations .step-area .step-area-inner span {
    display: block;
    font-size: 14px;
}

.verification-body .verification-informations .step-area .step-area-inner.phone span:last-child {
    margin-top: 7px;
}

.verification-body .verification-informations .step-area-inner.image1 span {
    margin-top: 25px;
}

.verification-body .verification-informations .step-area .step-area-inner button.show-image {
    border: 0px;
    border-radius: 5px;
    width: 75%;
    padding: 10px 0px;
    cursor: pointer;
    transition: .4s;
    background-color: #eee;
    margin-top: 15px;
}

.verification-body .verification-informations .step-area .step-area-inner button.show-image:hover {
    background-color: #ddd;
}

.verification-body .verification-informations .step-area .step-area-inner button.show-image i {
    margin-right: 7px;
}

.verification-body .verification-informations .step-area .step-area-inner-buttons {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.verification-body .verification-informations .step-area .step-area-inner-buttons button {
    border: 0px;
    border-radius: 5px;
    color: #fff;
    width: 100%;
    padding: 5px 0px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    transition: .4s;
}

.verification-body .verification-informations .step-area .step-area-inner-buttons button.approved {
    background-color: var(--global-success-color);
}

.verification-body .verification-informations .step-area .step-area-inner-buttons button.denied {
    background-color: var(--global-danger-color);
}

.verification-body .verification-informations .step-area .step-area-inner-buttons button:hover {
    opacity: .8;
}

/*========================= koyu tema düzenlemeleri */

body.dark .profile-buttons .button:hover {
    background-color: #2e2e2e;
    color: var(--global-yellow-color);
}

body.dark .profile-informations .item {
    color: #ddd;
}

body.dark .verification-body .verification-informations .step-area-title, body.dark .verification-body .verification-informations .step-area .step-area-inner-title, body.dark .verification-body .verification-informations .step-area .step-area-inner-buttons button {
    color: #ddd;
}

body.dark .verification-body .verification-informations .step-area .step-area-inner label {
    color: #bbb;
}

body.dark .verification-body .verification-informations .step-area-title div, body.dark .verification-body .verification-informations .step-area .step-area-inner button.show-image {
    background-color: #444;
    color: #eee;
}

body.dark .verification-body .verification-informations .step-area .step-area-inner button.show-image:hover {
    background-color: #333;
}

body.dark .verification-body .verification-informations .step-area, body.dark .verification-body .verification-informations .step-area .step-area-inner-content {
    box-shadow: 0px 0px 10px #00000060;
}

body.dark .verification-body .verification-informations .step-area.approved {
    box-shadow: 0px 0px 15px #1cc32c40;
}

body.dark .verification-body .verification-informations .step-area .step-area-inner-content.approved {
    box-shadow: 0px 0px 10px #1cc32c40;
}

body.dark .verification-body .verification-informations .step-area.pending {
    box-shadow: 0px 0px 15px #ffea5150;
}

body.dark .verification-body .verification-informations .step-area .step-area-inner-content.pending {
    box-shadow: 0px 0px 10px #ffea5150;
}

body.dark .verification-body .verification-informations .step-area.denied {
    box-shadow: 0px 0px 15px #ff1a0040;
}

body.dark .verification-body .verification-informations .step-area .step-area-inner-content.denied {
    box-shadow: 0px 0px 10px #ff1a0040;
}

body.dark .profile-body-center .profile-spot-orders .tabs-section {
    border-color: #3d3d3d;
}

body.dark .profile-future .general-values {
    border-color: #555;
}

body.dark .profile-future .general-values span {
    color: #eee;
}

body.dark .profile-future .general-values .credit-info {
    background-color: #2b2b2b;
    box-shadow: 0 0 10px #777;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 2000px){
    .profile-body-bottom .profile-history .scrollable-section-elements .element .item label {
        font-size: 10px;
    }
    .profile-body-bottom .profile-history .scrollable-section-elements .element .item span {
        font-size: 12px;
    }
    .verification-body .profile-informations {
        width: 30%;
    }
    .verification-body .verification-informations {
        width: calc(70% - 8px);
    }
    .verification-body .verification-informations {
        flex-direction: column;
    }
    .verification-body .profile-informations {
        min-height: auto;
    }
    .verification-body .verification-informations .verification-informations-left, .verification-body .verification-informations .verification-informations-right {
        width: 100%;
    }
    .verification-body .verification-informations .verification-informations-right {
        margin-top: 20px;
    }
}

@media screen and (max-width: 2000px) and (min-width: 1825px){
    .verification-body .verification-informations .step-area .step-area-inner button.show-image {
        width: 50%;
    }
}

@media screen and (max-width: 1825px){
    .profile-body .profile-body-left {
        display: block;
        width: calc(60% - 8px);
    }
    .profile-body .profile-body-right {
        width: 40%;
    }
    .profile-wallet, .profile-informations {
        width: 100%;
    }
    .profile-buttons {
        min-height: 1684px;
    }
    .verification-body .profile-informations {
        width: 35%;
    }
    .verification-body .verification-informations {
        width: calc(65% - 8px);
    }
}

@media screen and (max-width: 1600px){
    .verification-body .profile-informations {
        width: 40%;
    }
    .verification-body .verification-informations {
        width: calc(60% - 8px);
    }
}

@media screen and (max-width: 1600px){
    .profile-body-bottom {
        flex-direction: column;
    }
    .profile-body-bottom .profile-history {
        width: 100%;
    }
}

@media screen and (max-width: 1400px){
    .verification-body .verification-body-top {
        flex-direction: column;
    }
    .verification-body .profile-informations, .verification-body .verification-informations {
        width: 100%;
    }
    .verification-body .verification-informations .verification-informations-left, .verification-body .verification-informations .verification-informations-right, .verification-body .verification-informations .verification-informations-right .step-area {
        min-height: auto;
    }
}

@media screen and (max-width: 1400px) and (min-width: 825px){
    .verification-body .verification-informations .step-area .step-area-inner button.show-image {
        width: 65%;
    }
}

@media screen and (max-width: 1025px){
    .profile-body-top {
        display: block;
    }
    .profile-body .profile-body-left, .profile-body .profile-body-right {
        width: 100%;
    }
    .profile-informations, .profile-buttons {
        min-height: 175px;
    }
}

@media screen and (max-width: 1000px){
    .profile-header {
        justify-content: start;
        align-items: start;
        flex-direction: column;
    }
    .profile-header .item {
        flex-direction: row;
        justify-content: start;
        align-items: center;
        width: auto;
        padding-right: 0px;
        margin-bottom: 10px;
    }
    .profile-header .item:last-child {
        margin-bottom: 0px;
    }
    .profile-header .item label {
        font-weight: 600;
        font-size: 14px;
        margin-right: 8px;
    }
}

@media screen and (max-width: 700px){
    .verification-body .verification-informations .step-area .step-area-inner button.show-image {
        width: 100%;
    }
    .verification-body .verification-informations {
        padding: 25px;
    }
    .verification-body .verification-steps .verification-steps-title {
        padding: 30px 0px 0px 30px;
    }
}

@media screen and (max-width: 500px){
    .profile-header .item label {
        font-size: 12px;
    }
    .profile-header .item span {
        font-size: 13px;
    }
    .profile-informations {
        padding: 35px;
    }
    .profile-informations .item.br {
        margin-top: 20px;
    }
    .profile-informations .item label {
        font-size: 10px;
        margin-right: 3px;
    }
    .profile-informations .item span {
        display: flex;
        font-size: 12px;
    }
    .profile-informations .item span img {
        width: 16px !important;
    }
    .profile-informations .item .status-icon {
        margin-left: 0px;
        margin-right: 4px;
    }
    .profile-buttons .button {
        font-size: 12px;
    }
    .verification-body .verification-informations .step-area-title, .verification-body .verification-informations .step-area .step-area-inner-title, .verification-body .verification-informations .step-area .step-area-inner button.show-image {
        font-size: 12px;
    }
    .verification-body .verification-informations .step-area-title div {
        width: 25px;
        height: 25px;
    }
    .verification-body .verification-informations .step-area-info {
        padding-left: 5px;
        font-size: 12px;
    }
    .verification-body .verification-informations .step-area .step-area-inner label, .verification-body .verification-informations .step-area .step-area-inner span, .verification-body .verification-informations .step-area .step-area-inner-buttons button {
        font-size: 10px;
    }
    .verification-body .verification-informations .step-area.verification2 .step-area-inner.info .step-area-inner-content, .verification-body .verification-informations .step-area.verification2 .step-area-inner.info .step-area-inner-buttons {
        min-height: 380px;
    }
    .verification-body .verification-informations .step-area.verification2 .step-area-inner.phone .step-area-inner-content, .verification-body .verification-informations .step-area.verification2 .step-area-inner.phone .step-area-inner-buttons {
        min-height: 111px;
    }
    .verification-body .verification-informations .step-area.verification3 .step-area-inner.image1 .step-area-inner-content, .verification-body .verification-informations .step-area.verification3 .step-area-inner.image1 .step-area-inner-buttons {
        min-height: 122px;
    }
    .verification-body .verification-steps .verification-steps-title {
        font-size: 14px;
    }
}

@media screen and (max-width: 400px){
    .profile-header .item label {
        font-size: 10px;
        margin-right: 5px;
    }
    .profile-header .item span {
        font-size: 11px;
    }
    .verification-body .verification-informations {
        padding: 15px;
    }
    .verification-body .verification-informations .step-area .step-area-inner {
        justify-content: center;
        flex-direction: column;
    }
    .verification-body .verification-informations .step-area.verification2 .step-area-inner.info .step-area-inner-content, .verification-body .verification-informations .step-area.verification2 .step-area-inner.info .step-area-inner-buttons, .verification-body .verification-informations .step-area.verification2 .step-area-inner.phone .step-area-inner-content, .verification-body .verification-informations .step-area.verification2 .step-area-inner.phone .step-area-inner-buttons, .verification-body .verification-informations .step-area.verification3 .step-area-inner.image1 .step-area-inner-content, .verification-body .verification-informations .step-area.verification3 .step-area-inner.image1 .step-area-inner-buttons {
        min-height: auto;
    }
    .verification-body .verification-informations .step-area .step-area-inner-content {
        width: 100%;
    }
    .verification-body .verification-informations .step-area .step-area-inner-buttons {
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        margin-top: 10px;
    }
    .verification-body .verification-informations .step-area .step-area-inner-buttons button {
        width: 49%;
    }
    .verification-body .verification-steps .verification-steps-title {
        padding: 20px 0px 0px 20px;
    }
}

@media screen and (max-width: 350px){
    .profile-header .item label {
        font-size: 9px;
    }
    .profile-header .item span {
        font-size: 10px;
    }
    .verification-body .verification-informations .step-area .step-area-inner button.show-image {
        font-size: 10px;
    }
}
