@import '~boxicons/css/boxicons.min.css';

/*========================= genel renkler | eski yeşil: 1abc7b - eski kırmızı: f13005 | kullanım: var(--global-danger-color) */

:root {
    --global-success-color: #03a66d;
    --global-danger-color: #cf303f;
    --global-success-hover-color: #009360;
    --global-danger-hover-color: #bd2232;
    --global-dark1-color: #212121;
    --global-dark2-color: #1c1c1c;
    --global-yellow-color: #fbc92e;

    --verification-approved-color: #0b8116c7;
    --verification-denied-color: #d52f1cc7;
    --verification-pending-color: #eda800cc;
    --verification-default-color: #9b9b9b30;
}

/*========================= body */

body{
    background-color: #fafafa !important;
    color: #1c1c1c;
    font-family: 'Manrope' !important;
    -webkit-font-smoothing: subpixel-antialiased;
    margin: 0px;
    line-height: 1.5 !important;
    transition: .4s;
}

@keyframes open-animation {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes close-animation {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes close-open-animation {
    0% {opacity: 1;}
    50% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes fadeInSuccess {
    0% {color: var(--global-success-color);}
    100% {color: #333;}
}

@keyframes fadeInDanger {
    0% {color: var(--global-danger-color);}
    100% {color: #333;}
}

@keyframes fadeInSuccessForDark {
    0% {color: var(--global-success-color);}
    100% {color: #ccc;}
}

@keyframes fadeInDangerForDark {
    0% {color: var(--global-danger-color);}
    100% {color: #ccc;}
}

/*========================= input genel */

input[type=search]::-ms-clear{
    display: none; width : 0; height: 0;
}

input[type=search]::-ms-reveal{
    display: none; width : 0; height: 0;
}

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration{
    display: none;
}

/* Webkit tarayıcılar için (örn. Chrome, Safari) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox için */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Internet Explorer ve Edge için (eğer hala bu tarayıcıları destekliyorsanız) */
input[type="number"]::-ms-clear {
  display: none;
}

input[type="checkbox"].custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    background-color: #fafafa !important;
    border-radius: 8px !important;
    width: 22px !important;
    height: 22px !important;
    padding: 0px !important;
    position: relative !important;
    transition: .4s !important;
}

input[type="checkbox"].custom-checkbox:checked {
    background-color: #eee !important;
}

input[type="checkbox"].custom-checkbox:checked::after {
    content: '';
    display: block;
    width: 4px;
    height: 11px;
    margin-top: 2px;
    margin-left: 7px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(40deg);
}

body.dark input[type="checkbox"].custom-checkbox {
    background-color: #444 !important;
}

body.dark input[type="checkbox"].custom-checkbox:checked {
    background-color: #3f3f3f !important;
}

body.dark input[type="checkbox"].custom-checkbox:checked::after {
    border-color: #eee !important;
}

/*========================= div genel */

div, table, table tbody {
    scrollbar-width: auto;
    scrollbar-color: #aaa;
}

div::-webkit-scrollbar, table::-webkit-scrollbar, table tbody::-webkit-scrollbar {
    width: 5px;
    height: 6px;
}

div::-webkit-scrollbar-thumb, table::-webkit-scrollbar-thumb, table tbody::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 50px;
}

.p-dialog-mask {
    z-index: 9999999999 !important;
}

/*========================= scrollbarhide */

.scrollbarhide::-webkit-scrollbar {
    display: none;
}

.scrollbarhide{
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/*========================= main */

.main {
    margin-left: 310px;
    padding: 50px;
    padding-top: 120px;
    transition: all 400ms ease;
}

.main:not(.topsection) {
    animation-duration: 1s;
    animation-name: open-animation;
    position: relative;
}

.sidebar-close .main {
    margin-left: 110px;
}

.riseAndFallValueAnimation.success {
    animation: fadeInSuccess 4s ease-in-out;
}

.riseAndFallValueAnimation.danger {
    animation: fadeInDanger 4s ease-in-out;
}

body.dark .riseAndFallValueAnimation.success{
    animation: fadeInSuccessForDark 4s ease-in-out;
}

body.dark .riseAndFallValueAnimation.danger{
    animation: fadeInDangerForDark 4s ease-in-out;
}

/*========================= top section */

.topsection {
    padding: 0px;
}

.topsectionarea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 400ms ease;
    padding: 20px 50px;
    top: 26px;
    position: fixed;
    width: calc(100% - 310px);
    z-index: 999999;
}

body.scrolled .topsectionarea {
    background-color: #ffffff;
    top: 0px;
    box-shadow: 0px 10px 30px #2121212b;
}

.topsection .topsection-left{
    display: flex;
    align-items: center;
    justify-content: start;
}

.topsection .topsection-left .pagetitle{
    font-size: 24px;
    font-weight: 600;
    margin-left: 5px;
    color: #000;
}

.topsection .topsection-left .sidebar-openclose {
    display: none;
    margin-right: 25px;
}

.topsection .topsection-right {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-left: 15px;
    position: relative;
}

.topsection .topsection-right a {
    text-decoration: none;
}

.topsection .topsection-right i {
    cursor: pointer;
    font-size: 20px;
    margin-right: 15px;
    background-color: #e1e1e1;
    color: #212121;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .4s;
}

.topsection .topsection-right i.active {
    color: #212121;
    background-color: transparent;
    font-size: 26px;
}

.topsection .topsection-right i.login {
    font-size: 18px;
}

.topsection .topsection-right i.register {
    font-size: 16px;
}

.topsection .topsection-right i:not(.active):hover {
    color: #000;
    background-color: #d2d2d2;
}

.topsection .topsection-right i.lasticon{
    margin-right: 0px;
}

.topsection .topsection-right .notifications-count {
    position: absolute;
    top: 28px;
    right: 68px;
    color: #000;
    background-color: #e1e1e1;
    box-shadow: 0px 0px 12px #797979;
    font-size: 11px;
    font-weight: bold;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-family: 'Manrope';
}

.topsection .topsection-right .notifications-area {
    width: 450px;
    height: 500px;
    background-color: #fff;
    box-shadow: 0px 0px 15px #00000061;
    position: absolute;
    right: 50px;
    top: 82px;
    z-index: 99;
    border-radius: 12px;
    animation: open-animation .5s;
}

/*========================= footer */

.footer {
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-left: 310px;
    padding: 50px;
    padding-bottom: 0px;
    transition: all 400ms ease;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.sidebar-close .footer {
    margin-left: 110px;
}

.footer .top {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
}

.footer .bottom {
    margin-top: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 2px solid #f5f5f5;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.footer .item .title {
    margin-bottom: 5px;
    font-weight: 600;
}

.footer .item .links {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.footer .item .links a {
    text-decoration: none;
    color: inherit;
    transition: .4s;
    font-size: 14px;
}

.footer .item .links a:hover {
    color: var(--verification-pending-color);
}

.footer .item .links.social {
    flex-direction: row;
}

.footer .item .links.social a {
    margin-right: 15px;
    font-size: 18px;
}

.footer .item .links.social a:last-child {
    margin-right: 0px;
}

.footer .item .inner-item {
    margin-bottom: 30px;
}

.footer .item .inner-item:last-child {
    margin-bottom: 0px;
}

.footer .item .inner-item .links a {
    margin-bottom: 10px;
}

.footer .item .inner-item .links a:last-child {
    margin-bottom: 0px;
}

.footer .item .inner-item.contact .links a i {
    margin-right: 8px;
}

/*========================= Toastify */

.Toastify{
    z-index: 99999;
}

/*========================= all sections */

.allsections {
    margin-top: 8px;
    overflow: auto;
}

.allsections .allsections-title {
    padding: 0px 0px 20px 15px;
    font-weight: 600;
    font-size: 18px;
}

.allsections .allsections-title2 {
    padding: 20px 0px 0px 25px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.allsections .allsections-title2 i.special {
    padding-top: 1px;
}

.allsections .allsections-title2 .with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 25px;
    width: 100%;
}

.allsections .allsections-title2 .with-button a {
    padding: 4px 12px;
    background-color: #333;
    color: #eee;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    transition: .4s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.allsections .allsections-title2 .with-button a:hover {
    background-color: #444;
    color: var(--global-yellow-color);
}

.allsections .allsections-title3 {
    padding: 20px 0px 10px 15px;
}

.allsections .allsections-title i {
    margin-right: 10px;
}

.allsections .allsections-title2 .detail-button, .withdraw-user .detail-button, .verification-body .detail-button {
    font-size: 12px;
}

.allsections .allsections-title2 .detail-button i {
    margin-right: 0px;
}

.allsections-border {
    border-radius: 12px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
}

/*========================= koyu tema düzenlemeleri */

body.dark{
    background-color: var(--global-dark2-color) !important;
    color: #eee;
}

body.dark .topsection .topsection-right input{
    background-color: #333;
    border-color: #333;
    color: #fff;
}

body.dark .allsections-border{
    background-color: var(--global-dark1-color);
    border-color: var(--global-dark1-color);
}

body.dark .allsections .allsections-title2 .with-button a:hover {
    background-color: #2e2e2e;
    color: var(--global-yellow-color);
}

body.dark div{
    scrollbar-color: #444;
}

body.dark div::-webkit-scrollbar-thumb{
    background-color: #444;
}

body.dark.scrolled .topsectionarea {
    background-color: var(--global-dark1-color);
    box-shadow: 0px 0px 15px #ffffff1a;
    border-left: 1px solid #333;
}

body.dark .topsection .topsection-left .pagetitle {
    color: #eee;
}

body.dark .topsection .topsection-right i {
    background-color: #333;
    color: #eee;
}

body.dark .topsection .topsection-right i.active {
    background-color: transparent;
}

body.dark .topsection .topsection-right i:not(.active):hover {
    background-color: #444;
    color: #eee;
}

body.dark .topsection .topsection-right .notifications-area {
    background-color: #292929;
    box-shadow: 0px 0px 5px #ffffff2e;
}

body.dark .topsection .topsection-right .notifications-count {
    color: #ccc;
    background-color: #333;
    box-shadow: 0px 0px 12px #000000;
}

body.dark .footer{
    background-color: var(--global-dark1-color);
    color: #eee;
    border-color: #2b2b2b;
}

body.dark .footer .item .links a {
    color: #ddd;
}

body.dark .footer .item .links a:hover {
    color: var(--global-yellow-color);
}

body.dark .footer .bottom {
    border-color: #333;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 1615px) and (min-width: 1025px){
    .main.dashboard.logged-in {
        margin-bottom: 1185px;
    }
}

@media screen and (max-width: 1200px){
    body .main, body .footer {
        margin-left: 0px !important;
    }
    .topsectionarea {
        width: 100%;
    }
    .topsection .topsection-left .sidebar-openclose {
        display: flex;
        position: relative;
        margin: 0px;
        margin-right: 20px;
        z-index: 1;
    }
    .topsection .topsection-left .pagetitle {
        margin-left: 0px;
    }
    .topsection .topsection-right {
        margin-right: 0px;
    }
}

@media screen and (max-width: 1000px){
    .footer .top {
        justify-content: space-between;
    }
}

@media screen and (max-width: 820px){
    .main{
        padding: 50px 30px;
        padding-top: 120px;
    }
    .main.dashboard {
        padding: 25px 30px;
        padding-top: 120px;
    }
    .topsection {
        padding: 0px;
    }
    .topsectionarea {
        padding: 20px 30px;
    }
    .topsection .topsection-left .pagetitle {
        font-size: 18px;
    }
    .topsection .topsection-right .notifications-area{
        right: 30px;
    }
}

@media screen and (max-width: 750px){
    .main {
        padding: 40px 20px;
        padding-top: 110px;
    }
    .topsection {
        flex-direction: column;
        align-items: start;
        padding: 0px;
    }
    .topsectionarea {
        padding: 20px 20px;
    }
    .topsection .topsection-right {
        margin-right: 0px;
    }
    .topsection .topsection-right .notifications-area {
        right: 0px;
        top: 72px;
        width: 400px;
        height: 450px;
    }
    .topsection .topsection-right .notifications-area .section-notifications {
        height: 442px;
        max-height: 442px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-elements {
        height: 361px;
        max-height: 361px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-title label {
        font-size: 14px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-title div i {
        font-size: 14px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-date {
        font-size: 10px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-element .title-area {
        font-size: 12px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-element .title-area .title-right i {
        font-size: 12px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-element label {
        font-size: 12px;
    }
    .topsection .topsection-right i {
        width: 35px;
        height: 35px;
        font-size: 14px;
        margin-right: 8px;
    }
    .topsection .topsection-right i.active {
        font-size: 20px;
    }
    .topsection .topsection-right i.login {
        font-size: 12px;
    }
    .topsection .topsection-right i.register {
        font-size: 10px;
    }
    .topsection .topsection-right .notifications-count {
        top: 22px;
        right: 46px;
        font-size: 8px;
        width: 18px;
        height: 18px;
    }
    .footer .top {
        display: block;
    }
    .footer .top .item {
        width: 50%;
        display: inline-block;
        vertical-align: text-top;
        margin-bottom: 50px;
    }
    .footer .top .item:nth-child(3), .footer .top .item:nth-child(4) {
        margin-bottom: 0px;
    }
    .footer .item .inner-item {
        margin-bottom: 20px;
    }
    .footer .item .inner-item:last-child {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 500px){
    .main{
        padding: 30px 10px;
        padding-top: 100px;
    }
    .main.dashboard {
        padding: 20px 30px;
        padding-top: 120px;
    }
    .allsections .allsections-title {
        font-size: 16px;
        padding-left: 8px;
    }
    .allsections .allsections-title2 {
        font-size: 14px;
        padding-left: 20px;
    }
    .allsections .allsections-title2 .with-button {
        padding-right: 20px;
    }
    .allsections .allsections-title3 {
        font-size: 14px;
        padding-left: 15px;
    }
    .topsection {
        padding: 0px;
    }
    .topsectionarea {
        padding: 20px 10px;
    }
    .topsection .topsection-left .sidebar-openclose {
        margin-right: 10px;
        font-size: 14px;
        width: 20px;
        height: 20px;
    }
    .topsection .topsection-left .pagetitle {
        font-size: 16px;
    }
    .topsection .topsection-right .notifications-area {
        top: 55px;
        width: 350px;
        height: 400px;
    }
    .topsection .topsection-right .notifications-area .section-notifications {
        height: 392px;
        max-height: 392px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-elements {
        height: 325px;
        max-height: 325px;
    }
    .topsection .topsection-right .notifications-area .section-notifications {
        padding: 10px 15px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-title label {
        font-size: 12px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-title div i {
        font-size: 12px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-title div i:last-child {
        margin-left: 7px;
    }
    .topsection .topsection-right i {
        width: 30px;
        height: 30px;
        font-size: 12px;
        margin-right: 5px;
    }
    .topsection .topsection-right i.active {
        font-size: 16px;
    }
    .topsection .topsection-right .notifications-count {
        top: 20px;
        right: 38px;
        font-size: 6px;
        width: 14px;
        height: 14px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-date {
        font-size: 9px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-element .title-area .title-right i {
        font-size: 10px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-element {
        padding: 10px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-element .title-area {
        font-size: 11px;
        margin-bottom: 6px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-element label {
        font-size: 11px;
    }
    .footer .item .title {
        font-size: 12px;
    }
    .footer .item .links a {
        font-size: 10px;
    }
    .footer .item .links.social a {
        margin-right: 10px;
        font-size: 12px;
    }
    .footer .item .inner-item.contact .links a i {
        margin-right: 5px;
    }
    .footer .bottom {
        margin-top: 40px;
        font-size: 12px;
    }
}

@media screen and (max-width: 400px){
    .topsection .topsection-right .notifications-area {
        width: 300px;
        height: 375px;
    }
    .topsection .topsection-right .notifications-area .section-notifications {
        height: 367px;
        max-height: 367px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-elements {
        height: 300px;
        max-height: 300px;
    }
}

@media screen and (max-width: 350px){
    .footer .top .item {
        width: 100%;
        margin-bottom: 25px !important;
    }
    .footer .top .item:last-child {
        margin-bottom: 0px !important;
    }
    .footer .item .inner-item {
        margin-bottom: 25px;
    }
    .footer .item .inner-item:last-child {
        margin-bottom: 5px;
    }
    .footer .bottom {
        width: 100%;
        font-size: 12px;
        margin-top: 30px;
    }
}

@media screen and (max-width: 325px){
    .topsection .topsection-right .notifications-area {
        width: max-content;
    }
}

/*========================= primereact plugin for confirm dialog */
@import '~primereact/resources/themes/saga-blue/theme.css';
@import '~primereact/resources/primereact.min.css';
@import '~primeicons/primeicons.css';

.p-dialog-mask {
    z-index: 99999999 !important;
}

.p-dialog-mask .p-dialog {
    border-radius: 12px;
}

.p-dialog-mask .p-dialog .p-dialog-footer .p-confirm-dialog-reject {
    color: var(--global-danger-color);
    font-weight: 600;
}

.p-dialog-mask .p-dialog .p-dialog-footer .p-confirm-dialog-reject:hover {
    border-color: var(--global-danger-color);
}

.p-dialog-mask .p-dialog .p-dialog-footer .p-confirm-dialog-reject:focus {
    border-color: var(--global-danger-color);
    box-shadow: 0px 0px 5px var(--global-danger-color);
}

.p-dialog-mask .p-dialog.success .p-dialog-footer .p-confirm-dialog-reject {
    color: var(--global-success-color);
    font-weight: 600;
}

.p-dialog-mask .p-dialog.success .p-dialog-footer .p-confirm-dialog-reject:hover {
    border-color: var(--global-success-color);
}

.p-dialog-mask .p-dialog.success .p-dialog-footer .p-confirm-dialog-reject:focus {
    border-color: var(--global-success-color);
    box-shadow: 0px 0px 5px var(--global-success-color);
}

.p-dialog-mask .p-dialog .p-dialog-footer .p-confirm-dialog-accept {
    background: var(--global-danger-color);
    border-color: transparent;
    font-weight: 600;
}

.p-dialog-mask .p-dialog .p-dialog-footer .p-confirm-dialog-accept:hover {
    background: var(--global-danger-hover-color);
}

.p-dialog-mask .p-dialog .p-dialog-footer .p-confirm-dialog-accept:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #f7a2a2, 0 1px 2px 0 rgb(0, 0, 0);
}

body.dark .p-dialog-mask .p-dialog .p-dialog-footer .p-confirm-dialog-accept:focus {
    box-shadow: 0 0 0 2px var(--global-danger-hover-color), 0 0 0 4px #f7a2a2, 0 1px 2px 0 rgb(0, 0, 0);
}

.p-dialog-mask .p-dialog.success .p-dialog-footer .p-confirm-dialog-accept {
    background: var(--global-success-color);
    border-color: transparent;
    font-weight: 600;
}

.p-dialog-mask .p-dialog.success .p-dialog-footer .p-confirm-dialog-accept:hover {
    background: var(--global-success-hover-color);
}

.p-dialog-mask .p-dialog.success .p-dialog-footer .p-confirm-dialog-accept:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #a3f7a2, 0 1px 2px 0 rgb(0, 0, 0);
}

body.dark .p-dialog-mask .p-dialog.success .p-dialog-footer .p-confirm-dialog-accept:focus {
    box-shadow: 0 0 0 2px var(--global-success-hover-color), 0 0 0 4px #a3f7a2, 0 1px 2px 0 rgb(0, 0, 0);
}

/*========================= info-dialog */

.info-dialog{
    width: 600px;
}

.info-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.info-dialog .p-dialog-header {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.info-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none;
}

.info-dialog .user-details {
    margin-top: 10px;
    font-size: 15px;
}

.info-dialog .user-details.addresses {
    margin-top: 15px;
    font-size: 14px;
}

.info-dialog .user-details .item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ebebeb;
    border-top: 0px;
    background-color: #f9f9f9;
}

.info-dialog .user-details .item:first-child {
    border: 2px solid #ebebeb;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.info-dialog .user-details .item:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.info-dialog .user-details .item label {
    font-weight: bold;
    overflow-wrap: anywhere;
    width: 50%;
    padding: 10px;
}

.info-dialog .user-details .item div {
    overflow-wrap: anywhere;
    width: 50%;
    border-left: 2px solid #ebebeb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fff;
}

.info-dialog .user-details .item div i {
    background-color: #e9e9e9;
    padding: 5px;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    transition: .4s;
}

.info-dialog .user-details .item div i:hover {
    background-color: #ddd;
}

.info-dialog .user-details:not(.addresses) .item, .info-dialog .user-details:not(.addresses) .item div {
    text-align: center;
    justify-content: center;
}

.info-dialog .image-area {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

.info-dialog .image-area img {
    width: 100%;
    box-shadow: 0px 0px 10px #ddd;
    border-radius: 5px;
}

/*========================= table-area */

.MuiModal-root.MuiDialog-root {
    z-index: 999999999;
}

.MuiModal-root.MuiMenu-root.MuiPopover-root {
    z-index: 999999999;
}

.table-area {
    width: 100%;
    overflow: hidden !important;
    position: relative;
}

.table-area .table-select {
    position: absolute;
    z-index: 99;
    left: 10px;
    top: 10px;
}

.table-area .table-select .title {
    width: 200px;
    padding: 3px 10px;
    border-radius: 10px;
    border: 1px solid #aaa;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-area .table-select .menu {
    position: absolute;
    background-color: #f9f9f9;
    margin-top: 10px;
    z-index: 9;
    width: 200px;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0px 0px 5px #00000059;
    animation: open-animation .5s;
}

.table-area .table-select .menu label{
    display: block;
    cursor: pointer;
    transition: .4s;
    color: #333;
    padding: 2px 10px;
    border-radius: 8px;
    margin-bottom: 4px;
}

.table-area .table-select .menu label:last-child{
    margin-bottom: 0px;
}

.table-area .table-select .menu label.active, .table-area .table-select .menu label:hover{
    background-color: #e8e8e8;
}

.table-area .spot-currency-info {
    position: absolute;
    top: 14px;
    left: 15px;
    z-index: 9;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1px;
}

.table-area .spot-currency-info i {
    margin-right: 10px;
    font-size: 16px;
}

.table-area .noPagination .MuiTableContainer-root {
    height: 615px;
}

.table-area.page-spot .noPagination .MuiTableContainer-root {
    height: 815px;
}

.table-area .noPagination .MuiTableContainer-root tfoot, .table-area .noPagination .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-dense:last-child {
    display: none;
}

.table-area .withExpandMenu table tbody {
    display: table-row-group;
}

.table-area .withExpandMenu tr.MuiTableRow-root:not(.Mui-TableBodyCell-DetailPanel) {
    cursor: pointer;
}

.table-area .withExpandMenu tr:not(.Mui-TableBodyCell-DetailPanel) th:first-child, .table-area .withExpandMenu tr:not(.Mui-TableBodyCell-DetailPanel) td:first-child {
    display: none;
}

.table-area .withExpandMenu .mobile-table tr.MuiTableRow-root th:nth-child(2), .table-area .withExpandMenu .mobile-table tr.MuiTableRow-root td:nth-child(2) {
    display: none;
}

.table-area .withExpandMenu .submenu-values.process {
    align-items: start;
    display: flex;
    justify-content: start;
}

.table-area .withExpandMenu .submenu-values.process div span {
    font-size: 12px;
    font-weight: bold;
    color: #555;
}

.table-area .withExpandMenu .submenu-values.process div label {
    font-size: 14px;
    font-weight: bold;
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-left {
    align-items: start;
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: center;
    padding-left: 20px;
    width: 350px;
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-left div {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 5px;
    line-height: 18px;
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-left div:last-child {
    margin-bottom: 0px;
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-left div.success {
    color: var(--global-success-color);
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-left div label{
    margin-left: 5px;
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-right {
    align-items: start;
    border-left: 2px solid #ccc;
    display: flex;
    justify-content: start;
    max-height: 300px;
    min-height: 150px;
    overflow: auto;
    width: -webkit-fill-available;
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-right .empty-details {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: .6;
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-right .empty-details i {
    font-size: 26px;
    margin-bottom: 10px;
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-right .empty-details span {
    font-weight: 600;
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-right .details {
    width: 100%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-right .details .details-item {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    padding: 15px;
    border-bottom: 2px solid #ddd;
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-right .details .details-item:last-child {
    border: 0px;
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-right .details .details-item div {
    width: 20%;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-right .details .details-item div:last-child {
    margin-right: 0px;
}

.table-area .withExpandMenu .submenu-values.process .submenu-process-values-right .details .details-item div:nth-child(1), .table-area .withExpandMenu .submenu-values.process .submenu-process-values-right .details .details-item div:nth-child(3) {
    width: 30%;
}

.table-area .withExpandMenu .submenu-values .submenu-tabs {
    display: flex;
    padding: 15px 20px;
    background-color: #ededed;
}

.table-area .withExpandMenu .submenu-values .submenu-tabs .item {
    cursor: pointer;
    font-weight: normal;
}

.table-area .withExpandMenu .submenu-values .submenu-tabs .item:hover, .table-area .withExpandMenu .submenu-values .submenu-tabs .item.active {
    font-weight: bold;
}

.table-area .withExpandMenu .submenu-values .submenu-tabs .item:first-child {
    padding-right: 25px;
    margin-right: 25px;
    border-right: 2px solid #777;
}

.table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format {
    padding: 0px;
}

.table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format .scrollable-section-titles {
    padding: 15px 25px;
}

.table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format .scrollable-section-titles, .table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format .scrollable-section-elements .element {
    border-color: #e3e3e3;
}

.table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format .scrollable-section-elements {
    height: 330px;
}

.table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format .scrollable-section-elements .element {
    background-color: transparent;
}

.table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format .scrollable-section-elements .element:hover {
    background-color: #ededed !important;
}

.table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format .scrollable-section-elements .element .item span, .table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format .scrollable-section-titles span {
    font-size: 13px;
}

.table-area .rowVirtualization table thead tr {
    box-shadow: none;
}

.table-area .rowVirtualization:not(.withExpandMenu) table tbody tr td {
    border-width: 2px;
}

.table-area .rowVirtualization table tbody tr:last-child td {
    border-width: 1px;
}

.table-area .rowVirtualization.withExpandMenu tbody tr {
    position: relative;
    margin-bottom: -59px;
}

.table-area.page-spot .rowVirtualization.withExpandMenu tbody tr {
    margin-bottom: -58px;
}

.table-area .rowVirtualization.withExpandMenu tbody tr.Mui-TableBodyCell-DetailPanel {
    margin-bottom: 0;
}

.table-area .rowVirtualization.withExpandMenu tbody tr.Mui-TableBodyCell-DetailPanel td .MuiCollapse-root {
    width: 100%;
}

.table-area .bigTable table tr th, .table-area .bigTable table tr td {
    padding: 16px 0px;
    padding-right: 0px;
    padding-left: 5px;
    font-size: 13px;
    min-width: auto;
}

.table-area tr.Mui-TableBodyCell-DetailPanel, .table-area tr.Mui-TableBodyCell-DetailPanel td {
    padding: 0px;
    background-color: #f9f9f9;
}

.table-area span.priceForTable {
    align-items: center;
    display: flex;
    justify-content: start;
}

.table-area span.priceForTable i {
    margin: 0 5px;
}

.table-area .column3 table tr th, .table-area .column3 table tr td {
    width: calc(100% / 3);
}

.table-area .column4 table tr th, .table-area .column4 table tr td {
    width: calc(100% / 4);
}

.table-area .column5 table tr th, .table-area .column5 table tr td {
    width: calc(100% / 5);
}

.table-area .column6 table tr th, .table-area .column6 table tr td {
    width: calc(100% / 6);
}

.table-area .column7 table tr th, .table-area .column7 table tr td {
    width: calc(100% / 7);
}

.table-area .column8 table tr th, .table-area .column8 table tr td {
    width: calc(100% / 8);
}

.table-area .column9 table tr th, .table-area .column9 table tr td {
    width: calc(100% / 9);
}

.table-area .column10 table tr th, .table-area .column10 table tr td {
    width: calc(100% / 10);
}

.table-area .column11 table tr th, .table-area .column11 table tr td {
    width: calc(100% / 11);
}

.table-area .column12 table tr th, .table-area .column12 table tr td {
    width: calc(100% / 12);
}

.table-area .columnWithdrawRequestsSpecial table tr th:nth-child(1), .table-area .columnWithdrawRequestsSpecial table tr td:nth-child(1), .table-area .columnWithdrawRequestsSpecial table tr th:nth-child(2), .table-area .columnWithdrawRequestsSpecial table tr td:nth-child(2) {
    width: 22%;
}

.table-area .columnWithdrawRequestsSpecial table tr th:nth-child(3), .table-area .columnWithdrawRequestsSpecial table tr td:nth-child(3) {
    width: 30%;
}

.table-area .columnWithdrawRequestsSpecial table tr th:nth-child(4), .table-area .columnWithdrawRequestsSpecial table tr td:nth-child(4) {
    width: 14%;
}

.table-area .columnWithdrawRequestsSpecial table tr th:nth-child(5), .table-area .columnWithdrawRequestsSpecial table tr td:nth-child(5) {
    width: 12%;
}

.table-area-loading-animation {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.table-area-loading-animation .MuiLinearProgress-root {
    background-color: #aaa;
}

.table-area-loading-animation .MuiLinearProgress-root .MuiLinearProgress-bar {
    background-color: #777;
}

.table-area .list {
    position: relative;
}

.table-area .list.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 460px;
}

.table-area .list .MuiPaper-root.MuiPaper-elevation {
    box-shadow: none;
}

.table-area table tr th, .table-area table tr td {
    font-family: 'Manrope' !important;
}

.table-area table tr td {
    padding: 16px 18px;
    padding-right: 0px;
    font-weight: bold;
}

.table-area table td[colspan] {
    width: 100% !important;
}

.table-area table .td-text-with-button, .scrollable-section .td-text-with-button {
    display: inline-block;
}

.table-area table .td-text-with-button.customer-id, .scrollable-section .td-text-with-button.customer-id {
    min-width: 65px;
}

.table-area table .td-text-with-button.account-name {
    min-width: 120px;
}

.table-area table .detail-button, .scrollable-section .detail-button, .allsections-title .detail-button, .withdraw-user .detail-button, .verification-body .detail-button {
    margin-left: 10px;
    color: inherit;
    background-color: #ddd;
    padding: 3px 6px;
    padding-right: 7px;
    border-radius: 5px;
    transition: .4s;
    cursor: pointer;
    width: fit-content;
    display: inline-block;
    vertical-align: middle;
}

.table-area table .detail-button.no-padding, .scrollable-section .detail-button.no-padding {
    background-color: transparent;
    padding: 0px;
    border-radius: 0px;
    font-size: 18px;
}

.table-area table .detail-button:not(.no-padding):hover, .scrollable-section .detail-button:not(.no-padding):hover, .allsections-title .detail-button:not(.no-padding):hover, .withdraw-user .detail-button:not(.no-padding):hover, .verification-body .detail-button:not(.no-padding):hover {
    background-color: #ccc;
}

.table-area table .detail-button.no-padding:hover, .scrollable-section .detail-button.no-padding:hover {
    opacity: .8;
}

.table-area table a.detail-button, .table-area table .detail-button a, .scrollable-section a.detail-button, .scrollable-section .detail-button a {
    text-decoration: none;
}

.table-area table .td-text-with-button.customer-id, .scrollable-section .td-text-with-button.customer-id, .scrollable-section a.detail-button {
    margin-top: -3px;
}

.table-area table .detail-button.with-text i, .scrollable-section .detail-button.with-text i {
    margin-left: 10px;
}

.table-area table .detail-button.with-text, .scrollable-section .detail-button.with-text {
    padding: 3px 12px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-area table .pending-operation.success {
    color: var(--global-success-color);
}

.table-area table .pending-operation.danger {
    color: var(--global-danger-color);
}

.table-area table .mobile-info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 2px 4px;
}

.table-area table .mobile-info label {
    font-weight: 600;
    font-size: 8px;
}

.table-area table .mobile-info span {
    font-weight: 500;
    font-size: 9px;
}

.table-area  .total-info {
    padding: 50px;
    padding-top: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.table-area .total-info .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.table-area .total-info .item:first-child {
    margin-bottom: 35px;
}

.table-area .total-info .item label {
    font-weight: 600;
    padding-bottom: 5px;
    text-align: center;
}

.table-area .total-info .item span {
    font-size: 20px;
}

/*========================= scrollable-section */

.scrollable-section {
    padding: 20px;
    padding-right: 10px;
    width: 100%;
    height: 750px;
    overflow: hidden !important;
}

.scrollable-section.table-format {
    padding: 0px;
    padding-top: 20px;
    height: auto;
    max-height: 750px;
}

.scrollable-section .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 0px;
    height: -webkit-fill-available;
}

.scrollable-section .no-data i {
    font-size: 40px;
    opacity: 0.3;
}

.scrollable-section.table-format .no-data i {
    font-size: 34px;
}

.scrollable-section .no-data span {
    margin-top: 20px;
    font-size: 18px;
    opacity: .4;
}

.scrollable-section.table-format .no-data span {
    margin-top: 15px;
    font-size: 16px;
}

.scrollable-section-titles {
    padding: 10px 25px;
    border-bottom: 2px solid #f1f1f1;
}

.scrollable-section-titles span {
    display: inline-block;
    font-weight: bold;
    font-size: 15px;
}

.scrollable-section.column6 .scrollable-section-titles span, .scrollable-section.table-format.column6 .scrollable-section-elements .element .item {
    width: calc(100% / 6);
}

.scrollable-section.column7 .scrollable-section-titles span, .scrollable-section.table-format.column7 .scrollable-section-elements .element .item {
    width: calc(100% / 7);
}

.scrollable-section.column8 .scrollable-section-titles span, .scrollable-section.table-format.column8 .scrollable-section-elements .element .item {
    width: calc(100% / 8);
}

.scrollable-section.column9 .scrollable-section-titles span, .scrollable-section.table-format.column9 .scrollable-section-elements .element .item {
    width: calc(100% / 9);
}

.scrollable-section-elements {
    height: 710px;
    overflow: auto;
    padding-right: 10px;
}

.scrollable-section.table-format .scrollable-section-elements {
    height: 500px;
    padding-right: 0px;
}

.scrollable-section-elements .element {
    border: 2px solid #efefef;
    border-radius: 12px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    background-color: #fff;
    margin-bottom: 15px;
}

.scrollable-section.table-format .scrollable-section-elements .element {
    border: 0px;
    border-bottom: 2px solid #efefef;
    border-radius: 0px;
    margin-bottom: 0px;
    justify-content: start;
    align-items: center;
    padding: 15px 25px;
    transition: .4s;
}

.scrollable-section.table-format .scrollable-section-elements .element:hover {
    background-color: #f7f7f7;
}

.scrollable-section-elements .element:last-child {
    margin-bottom: 0px;
}

.scrollable-section-elements .element .element-area, .table-area .element-area {
    width: 49.5%;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.table-area .table-inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
}

.table-area .table-inputs .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 100%;
}

.table-area .table-inputs .item:first-child {
    margin-bottom: 10px;
}

.table-area .table-inputs label {
    font-weight: bold;
    font-size: 12px;
}

.table-area .table-input {
    padding: 5px;
    border-radius: 6px;
    outline: none;
    border: 2px solid #ddd;
    background-color: #fff;
    width: 100%;
}

.table-area .table-input.danger {
    box-shadow: 0px 0px 5px var(--global-danger-color);
    border-color: transparent !important;
}

.table-area .result-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
}

.table-area .result-buttons button {
    width: 100%;
    color: #eee;
    padding: 5px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: bold;
    transition: .4s;
    cursor: pointer;
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.table-area .result-buttons button.success {
    background-color: var(--global-success-color);
    color: #fff !important;
}

.table-area .result-buttons button.danger {
    background-color: var(--global-danger-color);
    color: #fff !important;
}

.table-area .result-buttons button:hover {
    opacity: .8;
}

.table-area .result-buttons button:first-child {
    margin-bottom: 10px;
}

.table-area .result-buttons button div div {
    width: 21px !important;
    height: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-area .result-buttons button div div svg {
    fill: #fff;
}

.table-area .element-area {
    width: 100%;
}

.table-area .element-area2 {
    flex-direction: row;
}

.table-area .element-area2 .left, .table-area .element-area2 .right {
    width: 49%;
}

.scrollable-section-elements .element .item, .table-area .element-area .item {
    display: flex;
    justify-content: start;
    align-items: start;
    margin-bottom: 5px;
}

.scrollable-section.table-format .scrollable-section-elements .element .item {
    margin-bottom: 0px;
}

.scrollable-section-elements .element .item:last-child, .table-area .element-area .item:last-child {
    margin-bottom: 0px;
}

.table-area .element-area2 .item {
    flex-direction: column;
}

.scrollable-section-elements .element .item label, .table-area .element-area .item label {
    font-weight: bold;
    color: #555;
    font-size: 13px;
    margin-right: 7px;
    padding-top: 2px;
    width: max-content;
}

.scrollable-section-elements .element .item span, .table-area .element-area .item span {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
}

.scrollable-section.table-format .scrollable-section-elements .element .item span {
    font-size: 14px;
    font-weight: 600;
}

.scrollable-section.table-format .scrollable-section-elements .element .item span .market, .table-area table tr td .market {
    align-items: center;
    display: flex;
}

.scrollable-section.table-format .scrollable-section-elements .element .item span .market img, .table-area table tr td .market img {
    border: 1px solid #ddd;
    border-radius: 10px;
    height: 27px;
    margin-right: 10px;
    padding: 4px;
    width: 27px;
}

.scrollable-section.table-format .scrollable-section-elements .element .item span .success, .table-area table tr td .success {
    color: var(--global-success-color);
}

.scrollable-section.table-format .scrollable-section-elements .element .item span .danger, .table-area table tr td .danger {
    color: var(--global-danger-color);
}

.scrollable-section.table-format .scrollable-section-elements .element .item span .total-value button, .table-area table tr td .total-value button {
    margin-left: 5px;
    padding: 0;
    vertical-align: text-top;
}

.scrollable-section.table-format .scrollable-section-elements .element .item span .total-value svg, .table-area table tr td .total-value svg {
    height: 20px;
    width: 20px;
}

.scrollable-section-elements .element .item.status span, .table-area .element-area .item.status span {
    border: 1px solid;
    border-radius: 5px;
    padding: 1px 5px;
}

.scrollable-section-elements .element .item.status span i, .table-area .element-area .item.status span i {
    margin-right: 5px;
}

.scrollable-section-elements .element .item.status.approved span, .table-area .element-area .item.status.approved span {
    border-color: #0b811650;
    background-color: #0b811615;
}

.scrollable-section-elements .element .item.status.pending span, .table-area .element-area .item.status.pending span {
    border-color: #eda80050;
    background-color: #eda80015;
}

.scrollable-section-elements .element .item.status.denied span, .table-area .element-area .item.status.denied span {
    border-color: #d52f1c50;
    background-color: #d52f1c15;
}

.scrollable-section-elements .element .item.status.approved span i, .table-area .element-area .item.status.approved span i {
    color: var(--verification-approved-color);
}

.scrollable-section-elements .element .item.status.pending span i, .table-area .element-area .item.status.pending span i {
    color: var(--verification-pending-color);
}

.scrollable-section-elements .element .item.status.denied span i, .table-area .element-area .item.status.denied span i {
    color: var(--verification-denied-color);
}

.scrollable-section-elements .loading, .scrollable-section-elements .loading div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.scrollable-section-elements .loading div {
    width: 50px;
    height: 50px;
}

.choose-date-area {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 30px 20px;
}

.choose-date-area div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.choose-date-area div:first-child {
    margin-right: 20px;
}

.choose-date-area div label {
    font-size: 14px;
    padding-left: 2px;
    padding-bottom: 2px;
    font-weight: bold;
}

.choose-date-area div input {
    border-radius: 6px;
    border: 2px solid #ddd;
    padding: 5px 10px;
    outline: none;
    width: 100%;
}

.choose-date-button {
    margin-left: 20px;
    margin-bottom: 30px;
    width: 315px;
    background-color: #333;
    color: #eee;
    padding: 10px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: bold;
    transition: .4s;
    cursor: pointer;
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.choose-date-button:hover {
    background-color: #444;
    color: var(--global-yellow-color);
}

.tabs-section {
    display: flex;
    justify-content: start;
    align-items: center;
}

.allsections .tabs-section {
    padding: 20px 15px;
    margin-bottom: 5px;
    border-bottom: 2px solid #f1f1f1;
}

.tabs-section .item {
    margin-right: 50px;
    cursor: pointer;
    color: #555;
}

.tabs-section .item:last-child {
    margin-right: 0px;
}

.tabs-section .item.active, .tabs-section .item:hover {
    font-weight: bold;
    color: #000;
}

/*========================= koyu tema düzenlemeleri */

body.dark .p-dialog-mask .p-dialog{
    box-shadow: 0px 0px 15px #ffffff24;
}

body.dark .p-dialog-mask .p-dialog .p-dialog-header .p-dialog-header-icon{
    color: #ccc;
}

body.dark .p-dialog-mask .p-dialog .p-dialog-header .p-dialog-header-icon:hover {
    color: #ccc;
    background-color: #555;
}

body.dark .p-dialog-mask .p-dialog .p-dialog-header, body.dark .p-dialog-mask .p-dialog .p-dialog-content, body.dark .p-dialog-mask .p-dialog .p-dialog-footer {
    background-color: #333;
    color: #fff;
}

body.dark .info-dialog .user-details .item, body.dark .info-dialog .user-details .item div {
    border-color: #3f3f3f;
}

body.dark .info-dialog .user-details .item {
    background-color: #2e2e2e;
    color: #ccc;
}

body.dark .info-dialog .user-details .item div {
    background-color: #333;
}

body.dark .info-dialog .user-details .item div i {
    background-color: #444;
}

body.dark .info-dialog .user-details .item div i:hover {
    background-color: #555;
}

body.dark .table-area-loading-animation .MuiLinearProgress-root {
    background-color: #555;
}

body.dark .table-area-loading-animation .MuiLinearProgress-root .MuiLinearProgress-bar {
    background-color: #333;
}

body.dark .table-area .table-select .title {
    background-color: #333;
    color: #ccc;
    border-color: #555;
}

body.dark .table-area .table-select .menu {
    background-color: #333;
    box-shadow: 0px 0px 5px #dcdcdc36;
}

body.dark .table-area .table-select .menu label {
    color: #ccc;
}

body.dark .table-area .table-select .menu label.active, body.dark .table-area .table-select .menu label:hover{
    background-color: #444;
}

body.dark .table-area p, body.dark .table-area .MuiSelect-select{
    color: #ccc;
}

body.dark .table-area .list:not(.loading) svg {
    fill: #ddd;
}

body.dark .table-area .list .MuiPaper-root {
    background-color: #212121;
}

body.dark .table-area .list .MuiToolbar-root, body.dark .table-area .list table.MuiTable-root {
    background-color: #212121;
}

body.dark .table-area .list .MuiToolbar-root button {
    color: #ccc;
}

body.dark .table-area .list tr.MuiTableRow-root {
    background-color: #212121;
}

body.dark .table-area .list tr.MuiTableRow-root td, body.dark .table-area .list tr.MuiTableRow-root th {
    color: #ccc;
    border-color: #444;
}

body.dark .table-area tr.Mui-TableBodyCell-DetailPanel, body.dark .table-area tr.Mui-TableBodyCell-DetailPanel:hover, body.dark .table-area tr.Mui-TableBodyCell-DetailPanel td {
    background-color: #2c2c2c !important;
}

body.dark .table-area .withExpandMenu .submenu-values.process div span {
    color: #eee;
}

body.dark .table-area .withExpandMenu .submenu-values.process div label {
    font-weight: 500;
    color: #fff;
}

body.dark .table-area .withExpandMenu .submenu-values.process div label.success, body.dark .table-area .withExpandMenu .submenu-values.process div.success label {
    font-weight: bold;
    color: var(--global-success-color);
}

body.dark .table-area .withExpandMenu .submenu-values.process div label.danger, body.dark .table-area .withExpandMenu .submenu-values.process div.danger label {
    font-weight: bold;
    color: var(--global-danger-color);
}

body.dark .table-area .withExpandMenu .submenu-values.process div.success span {
    color: var(--global-success-color);
}

body.dark .table-area .withExpandMenu .submenu-values.process div.danger span {
    color: var(--global-danger-color);
}

body.dark .table-area .withExpandMenu .submenu-values.process .submenu-process-values-right .empty-details {
    opacity: .4;
}

body.dark .table-area .withExpandMenu .submenu-values.process .submenu-process-values-right, body.dark .table-area .withExpandMenu .submenu-values.process .submenu-process-values-right .details .details-item {
    border-color: #555;
}

body.dark .table-area .withExpandMenu .submenu-values .submenu-tabs {
    background-color: #333;
}

body.dark .table-area .withExpandMenu .submenu-values .submenu-tabs .item:hover, body.dark .table-area .withExpandMenu .submenu-values .submenu-tabs .item.active {
    color: #eee;
}

body.dark .table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format .scrollable-section-titles, body.dark .table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format .scrollable-section-elements .element {
    border-color: #444444;
}

body.dark .table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format .scrollable-section-elements .element:hover {
    background-color: #333 !important;
}

body.dark .table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format .scrollable-section-elements .element .item span {
    color: #ddd;
}

body.dark .table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format .scrollable-section-elements .element .item span .success {
    color: var(--global-success-color);
}

body.dark .table-area .withExpandMenu .submenu-values .submenu-elements .scrollable-section.table-format .scrollable-section-elements .element .item span .danger {
    color: var(--global-danger-color);
}

body.dark .table-area .list tbody tr.MuiTableRow-root:hover, body.dark .table-area .list tbody tr.MuiTableRow-root:hover td {
    background-color: #44444424;
}

body.dark .table-area table .detail-button, body.dark .scrollable-section .detail-button, body.dark .allsections-title .detail-button, body.dark .withdraw-user .detail-button, body.dark .verification-body .detail-button {
    color: #ddd;
}

body.dark .table-area table .detail-button:not(.no-padding), body.dark .scrollable-section .detail-button:not(.no-padding), body.dark .allsections-title .detail-button:not(.no-padding), body.dark .withdraw-user .detail-button:not(.no-padding), body.dark .verification-body .detail-button:not(.no-padding) {
    background-color: #444;
}

body.dark .table-area table .detail-button:not(.no-padding):hover, body.dark .scrollable-section .detail-button:not(.no-padding):hover, body.dark .allsections-title .detail-button:not(.no-padding):hover, body.dark .withdraw-user .detail-button:not(.no-padding):hover, body.dark .verification-body .detail-button:not(.no-padding):hover {
    background-color: #555;
}

body.dark .MuiModal-root .MuiPaper-root {
    background-color: #333;
    color: #ddd;
}

body.dark .MuiModal-root .MuiPaper-root li.MuiButtonBase-root:hover {
    background-color: #3e3e3e;
}

body.dark .MuiModal-root .MuiPaper-root li.MuiButtonBase-root.Mui-selected {
    background-color: #444;
}

body.dark .info-dialog .image-area img {
    box-shadow: 0px 0px 10px #555;
}

body.dark .tabs-section .item {
    color: #ddd;
}

body.dark .tabs-section .item.active, body.dark .tabs-section .item:hover {
    color: #fff;
}

body.dark .scrollable-section-titles {
    border-color: #444;
}

body.dark .scrollable-section-elements .element {
    background-color: transparent;
    border-color: #353535;
}

body.dark .scrollable-section.table-format .scrollable-section-elements .element {
    border-color: #333;
}

body.dark .scrollable-section.table-format .scrollable-section-elements .element:hover {
    background-color: #272727;
}

body.dark .scrollable-section-elements .element .item label, body.dark .table-area .element-area .item label {
    color: #bbb;
}

body.dark .scrollable-section-elements .element .item span, body.dark .table-area .element-area .item span {
    color: #fff;
}

body.dark .scrollable-section.table-format .scrollable-section-elements .element .item span {
    color: #ddd;
}

body.dark .scrollable-section.table-format .scrollable-section-elements .element .item span .success, body.dark .table-area table tr td .success {
    color: var(--global-success-color);
}

body.dark .scrollable-section.table-format .scrollable-section-elements .element .item span .danger, body.dark .table-area table tr td .danger {
    color: var(--global-danger-color);
}

body.dark .scrollable-section.table-format .scrollable-section-elements .element .item span .market img, body.dark .table-area table tr td .market img {
    background-color: #505050bd;
    border-color: #aaaaaa00;
}

body.dark .scrollable-section.table-format .scrollable-section-elements .element .item span .total-value svg {
    fill: #ddd;
}

body.dark .choose-date-area div input {
    background-color: #555;
    color: #fff;
    border-color: #555;
}

body.dark .choose-date-button:hover {
    background-color: #2e2e2e;
    color: var(--global-yellow-color);
}

body.dark .table-area .table-input {
    background-color: #444;
    border-color: #555;
    color: #fff;
}

body.dark .allsections .tabs-section {
    border-color: #333;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 1800px){
    .table-area table tr th, .table-area table tr td {
        padding: 16px 8px;
        padding-right: 0px;
    }
}

@media screen and (max-width: 1700px){
    .table-area .columnWithdrawRequestsSpecial table tr th:nth-child(1), .table-area .columnWithdrawRequestsSpecial table tr td:nth-child(1), .table-area .columnWithdrawRequestsSpecial table tr th:nth-child(2), .table-area .columnWithdrawRequestsSpecial table tr td:nth-child(2) {
        width: 28%;
    }
    .table-area .columnWithdrawRequestsSpecial table tr th:nth-child(3), .table-area .columnWithdrawRequestsSpecial table tr td:nth-child(3) {
        width: 20%;
    }
    .table-area .columnWithdrawRequestsSpecial table tr th:nth-child(4), .table-area .columnWithdrawRequestsSpecial table tr td:nth-child(4) {
        width: 20%;
    }
    .table-area .columnWithdrawRequestsSpecial table tr th:nth-child(5), .table-area .columnWithdrawRequestsSpecial table tr td:nth-child(5) {
        width: 6%;
    }
}

@media screen and (max-width: 1550px){
    .table-area .list tr.MuiTableRow-root th, .table-area .list tr.MuiTableRow-root td {
        font-size: 12px;
        padding: 12px 8px;
        padding-right: 0px;
    }
    .table-area .list .MuiButtonBase-root svg {
        width: 20px;
        height: 20px;
    }
    .table-area .list tr.MuiTableRow-root span label {
        width: 90px;
        font-size: 12px;
        padding: 6px 0px;
    }
    .table-area .spot-currency-info {
        top: 13px;
    }
    .table-area table .td-text-with-button.customer-id, .scrollable-section .td-text-with-button.customer-id {
        min-width: 55px;
    }
    .table-area table .td-text-with-button.account-name {
        min-width: 100px;
    }
}

@media screen and (max-width: 1350px){
    .table-area .table-select .title {
        font-size: 12px;
        padding: 3px 8px;
    }
    .table-area .table-select .menu {
        font-size: 12px;
    }
    .table-area .spot-currency-info {
        top: 11px;
        font-size: 16px;
    }
    .table-area .spot-currency-info i {
        margin-right: 7px;
        font-size: 14px;
    }
    .table-area .list.loading div div, .submenu-values .loading div div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .table-area .list.loading svg, .section-traderecenttrades-list.loading svg {
        width: 50px;
    }
    .table-area .list tr.MuiTableRow-root th, .table-area .list tr.MuiTableRow-root td {
        font-size: 10px;
        padding: 10px 5px;
        padding-right: 0px;
    }
    .table-area .list .MuiButtonBase-root svg {
        width: 15px;
        height: 15px;
    }
    .table-area .list tr.MuiTableRow-root span label {
        width: 90px;
        font-size: 12px;
        padding: 6px 0px;
    }
    .table-area table .td-text-with-button.customer-id, .scrollable-section .td-text-with-button.customer-id {
        min-width: 40px;
    }
    .table-area table .td-text-with-button.account-name {
        min-width: 85px;
    }
}

@media screen and (max-width: 1000px){
    .table-area .list .MuiTableSortLabel-root svg {
        width: 12px;
    }
    .table-area .list tr.MuiTableRow-root span label {
        width: 75px;
        font-size: 10px;
        padding: 5px 0px;
    }
    .table-area table .detail-button, .scrollable-section .detail-button {
        margin-left: 6px;
    }
    .table-area table .detail-button:not(.no-padding), .scrollable-section .detail-button:not(.no-padding) {
        margin-left: 6px;
        padding: 2px 4px;
        font-size: 8px;
    }
    .table-area table .detail-button:not(.no-padding).with-text, .scrollable-section .detail-button:not(.no-padding).with-text {
        padding: 2px 8px;
    }
    .table-area table .detail-button.no-padding, .scrollable-section .detail-button.no-padding {
        font-size: 14px;
    }
    .table-area table .detail-button.with-text i, .scrollable-section .detail-button.with-text i {
        margin-left: 7px;
    }
    .table-area table .detail-button.with-text, .scrollable-section .detail-button.with-text {
        margin-left: 0px;
    }
    .scrollable-section-elements .element .item label, .table-area .element-area .item label {
        font-size: 11px;
    }
    .scrollable-section-elements .element .item span, .table-area .element-area .item span {
        font-size: 13px;
    }
}

@media screen and (max-width: 900px){
    .table-area .table-select .title {
        width: 175px;
        font-size: 10px;
        padding: 3px 5px;
        border-radius: 7px;
    }
    .table-area .table-select .menu {
        width: 175px;
        font-size: 10px;
        border-radius: 7px;
    }
    .table-area .table-select .menu label {
        border-radius: 7px;
    }
    .table-area .list tr.MuiTableRow-root th, .table-area .list tr.MuiTableRow-root td {
        font-size: 9px;
        padding: 8px 3px;
        padding-right: 0px;
    }
    .table-area table .td-text-with-button.account-name {
        min-width: 80px;
    }
}

@media screen and (max-width: 800px){
    .table-area .list td[colspan] p.MuiTypography-root {
        font-size: 10px;
    }
    .table-area table .td-text-with-button.account-name {
        min-width: 75px;
    }
}

@media screen and (max-width: 750px){
    .info-dialog{
        width: 95%;
    }
    .scrollable-section-elements .element .item, .table-area .element-area .item {
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }
}

@media screen and (max-width: 725px){
    .table-area .list tr.MuiTableRow-root {
        font-size: 12px;
    }
    .table-area .list .MuiTablePagination-root, .table-area .list .MuiTablePagination-selectLabel, .table-area .list .MuiTablePagination-displayedRows {
        font-size: 12px;
    }
}

@media screen and (max-width: 720px){
    .table-area .table-select {
        top: 13px;
    }
    .table-area .list .MuiBox-root {
        padding-top: 0px !important;
    }
}

@media screen and (max-width: 675px){
    .table-area .list .MuiTableSortLabel-root svg {
        width: 10px;
    }
}

@media screen and (max-width: 650px){
    .table-area .list.loading svg, .section-traderecenttrades-list.loading svg {
        width: 30px;
    }
    .table-area .list tr.MuiTableRow-root {
        font-size: 10px;
    }
}

@media screen and (max-width: 500px){
    .p-dialog .p-dialog-header .p-dialog-title {
        font-size: 16px;
    }
    .p-dialog .p-dialog-content {
        padding: 0px 10px 15px 10px;
    }
    .info-dialog .user-details {
        font-size: 10px;
    }
    .info-dialog .user-details.addresses {
        font-size: 9px;
    }
    .info-dialog .user-details .item label, .info-dialog .user-details .item div {
        padding: 5px;
    }
    .table-area .table-select {
        top: 14px;
    }
    .table-area .table-select .title {
        width: 150px;
        font-size: 8px;
        border-radius: 5px;
    }
    .table-area .table-select .menu {
        width: 150px;
        font-size: 8px;
        border-radius: 7px;
        margin-top: 7px;
        padding: 4px;
    }
    .table-area .table-select .menu label {
        border-radius: 5px;
        margin-bottom: 2px;
        padding: 2px 7px;
    }
    .table-area .table-select .menu label:last-child {
        margin-bottom: 0px;
    }
    .table-area .spot-currency-info {
        top: 13px;
        font-size: 12px;
    }
    .table-area .spot-currency-info i {
        font-size: 10px;
    }
    .table-area .list tr.MuiTableRow-root {
        font-size: 8px;
    }
    .table-area .list .MuiTablePagination-root, .table-area .list .MuiTablePagination-selectLabel, .table-area .list .MuiTablePagination-displayedRows {
        font-size: 8px;
    }
    .edit-process-dialog .edit-process-form .market-info div:last-child {
        font-size: 15px;
    }
    .table-area table .detail-button, .scrollable-section .detail-button {
        margin-left: 4px;
    }
    .table-area table .detail-button:not(.no-padding), .scrollable-section .detail-button:not(.no-padding) {
        padding: 2px 3px;
        font-size: 6px;
        border-radius: 3px;
    }
    .table-area table .detail-button:not(.no-padding).with-text, .scrollable-section .detail-button:not(.no-padding).with-text {
        padding: 2px 5px;
    }
    .table-area table .detail-button.no-padding, .scrollable-section .detail-button.no-padding {
        font-size: 10px;
    }
    .table-area table .detail-button.with-text i, .scrollable-section .detail-button.with-text i {
        margin-left: 4px;
    }
    .table-area table .detail-button.with-text, .scrollable-section .detail-button.with-text {
        margin-left: 0px;
    }
    .table-area table .mobile-info label {
        font-size: 6px;
    }
    .table-area table .mobile-info span {
        font-size: 8px;
    }
    .table-area .list .MuiTablePagination-root .MuiToolbar-root {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
    }
    .table-area .list .MuiTablePagination-root .MuiToolbar-root .MuiTablePagination-spacer, .table-area .list .MuiTablePagination-root .MuiToolbar-root .MuiTablePagination-selectLabel, .table-area .list .MuiTablePagination-root .MuiToolbar-root .MuiInputBase-root, .table-area .list .MuiTablePagination-root .MuiToolbar-root .MuiTablePagination-displayedRows, .table-area .list .MuiTablePagination-root .MuiToolbar-root .MuiTablePagination-actions {
        margin-left: 0px;
        margin-right: 0px;
        justify-content: center;
    }
    .table-area .list .MuiTablePagination-root .MuiToolbar-root .MuiInputBase-root div {
        padding: 4px 0px;
        padding-right: 10px;
    }
    .table-area .list .MuiTablePagination-root .MuiToolbar-root .MuiInputBase-root svg {
        width: 13px;
        height: 21px;
        margin-right: -3px;
    }
    .MuiModal-root .MuiPaper-root li.MuiButtonBase-root {
        padding: 7px !important;
        font-size: 10px;
        min-height: auto;
    }
    .table-area .total-info .item label {
        font-size: 12px;
    }
    .table-area .total-info .item span {
        font-size: 16px;
    }
    .table-area table .td-text-with-button.customer-id, .scrollable-section .td-text-with-button.customer-id {
        min-width: 35px;
    }
    .table-area table .td-text-with-button.account-name {
        min-width: 70px;
    }
    .scrollable-section-elements .element .item label, .table-area .element-area .item label {
        font-size: 9px;
    }
    .scrollable-section-elements .element .item span, .table-area .element-area .item span {
        font-size: 11px;
    }
    .scrollable-section-elements .element .item.status span, .table-area .element-area .item.status span {
        padding: 1px 3px;
        font-size: 10px;
    }
    .choose-date-area div label {
        font-size: 10px;
    }
    .choose-date-area div input {
        font-size: 12px;
    }
    .choose-date-area div:first-child {
        margin-right: 10px;
    }
    .choose-date-button {
        font-size: 12px;
        padding: 7px;
        width: 250px;
    }
}

@media screen and (max-width: 400px){
    .table-area .table-select {
        top: 16px;
    }
    .table-area .table-select .title {
        width: 100px;
        font-size: 7px;
        padding: 2px 3px;
    }
    .table-area .table-select .menu {
        width: 100px;
        font-size: 8px;
        border-radius: 7px;
        margin-top: 7px;
        padding: 4px;
    }
    .table-area .list .MuiBox-root.Mui-ToolbarDropZone {
        padding-top: 10px !important;
    }
    .table-area .list tr.MuiTableRow-root th, .table-area .list tr.MuiTableRow-root td {
        font-size: 7px;
        padding: 6px 2px;
        padding-right: 0px;
    }
    .scrollable-section-elements .element .item label, .table-area .element-area .item label {
        font-size: 7px;
    }
    .scrollable-section-elements .element .item span, .table-area .element-area .item span {
        font-size: 9px;
    }
    .scrollable-section-elements .element .item.status span, .table-area .element-area .item.status span {
        font-size: 8px;
    }
}

@media screen and (max-width: 350px){
    .table-area .list tr.MuiTableRow-root th, .table-area .list tr.MuiTableRow-root td {
        font-size: 6px;
    }
    .choose-date-area div:first-child {
        margin-right: 0px;
    }
    .choose-date-area {
        justify-content: space-between;
    }
    .choose-date-area div {
        width: 49%;
    }
    .choose-date-button {
        width: 87%;
    }
}
