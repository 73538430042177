/*========================= sidebar genel */

.sidebar{
    width: 310px;
    background-color: #fff;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    z-index: 99999999;
    position: fixed;
    top: 0px;
    outline: 0px;
    left: 0px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    padding: 32px 0px 40px 0px;
    font-size: 1rem;
    -webkit-box-pack: justify;
    justify-content: normal;
    align-items: flex-start;
    height: 100%;
    font-weight: 600 !important;
    color: #333;
    transition: all 400ms ease;
    overflow: auto;
}

/*========================= sidebar logo */

.sidebar-logo {
    display: flex;
    align-items: start;
    justify-content: start;
    width: auto;
    margin-bottom: 25px;
    text-decoration: none;
    color: #000;
    margin-top: 8px;
    margin-left: 32px;
}

.sidebar-logo img {
    width: 55px;
    height: 65px;
    transition: all 400ms ease;
    margin-left: -7px;
}

body:not(.dark) .sidebar-logo .logo-darkmode {
    display: none;
}

body.dark .sidebar-logo .logo-lightmode {
    display: none;
}

.sidebar-logo div {
    font-weight: 600;
    font-size: 38px;
    transition: all 400ms ease;
    padding-top: 1px;
}

.sidebar-openclose {
    background-color: var(--global-yellow-color);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    font-size: 20px;
    border-radius: 5px;
    position: fixed;
    z-index: 99999999;
    cursor: pointer;
    transition: all 400ms ease;
    margin-top: 21px;
    margin-left: 294px;
}

.MuiTooltip-popper {
    z-index: 9999999999999 !important;
}

/*========================= sidebar linkler */

#react-tiny-popover-container {
    z-index: 9;
}

.sidebar-links {
    margin-top: 25px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sidebar-links a, #react-tiny-popover-container .submenu a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;
    transition: .5s;
    margin-bottom: 20px;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    font-size: 17px;
    outline: none;
    text-decoration: none;
    width: 100%;
    position: relative;
    z-index: 99999999;
}
.sidebar-links a:not(.notclickable):hover, #react-tiny-popover-container .submenu a:not(.notclickable):hover {
    color: #000;
}

.sidebar-links a:not(.notclickable).active, #react-tiny-popover-container .submenu a:not(.notclickable).active {
    font-weight: bold;
    color: #000;
}

.sidebar-links a .info {
    height: 100%;
}

.sidebar-links a .mark {
    width: 5px;
    height: 100%;
    display: inline-block;
    vertical-align: bottom;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-right: 27px;
    transition: all 200ms ease;
}

.sidebar-links a.active .mark {
    background-color: #000;
}

.sidebar-links a i {
    display: inline-block;
    margin-right: 20px;
}

.sidebar-links a i.submenu-arrow {
    font-size: 14px;
    padding-top: 5px;
    padding-left: 5px;
}

.sidebar-links a span {
    display: inline-block;
}

.sidebar-links a .signal {
    margin-left: 10px;
    background-color: var(--global-danger-color);
    color: #fff;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    padding-top: 1px;
}

.sidebar-links a .signal i {
    margin: 0px;
}

.sidebar-links a .count {
    margin-left: 10px;
    background-color: #eee;
    border-radius: 50px;
    padding: 0px 5px;
    min-width: 35px;
    height: 22px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    transition: background-color .4s;
}

.sidebar-links a:not(.notclickable):hover .count, .sidebar-links a:not(.notclickable).active .count {
    background-color: #ddd;
}

#react-tiny-popover-container .submenu {
    animation: open-animation .5s;
    position: relative;
    min-width: 250px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px #ddd;
    border-radius: 5px;
    top: 78px;
    left: calc(100% + 40px);
    transition: all 200ms ease;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

#react-tiny-popover-container .submenu a {
    margin-bottom: 10px;
    font-size: 16px;
    justify-content: start;
}

#react-tiny-popover-container .submenu a:last-child {
    margin-bottom: 0px;
}

#react-tiny-popover-container .submenu a i {
    font-size: 14px;
    min-width: 27px;
    margin-right: 0px;
}

#react-tiny-popover-container .submenu a span {
    transform: none !important;
    opacity: 1 !important;
}

/*========================= sidebar user */

.sidebar-user{
    margin-top: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 29px;
    text-decoration: none;
    color: inherit;
}

.sidebar-user .user-img {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

.sidebar-user .user-img .image {
    border-radius: 50px;
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

.sidebar-user .user-img .image img {
    width: 50px;
    display: inline-block;
    border-radius: 50px;
    border: 2px solid transparent;
}

.sidebar-user .user-img .image.verify{
    background-color: var(--global-success-color);
}
.sidebar-user .user-img .image.half-verify{
    background-image: linear-gradient(to left, var(--global-success-color) 50%, #bababa 50%);
}
.sidebar-user .user-img .image.not-verify{
    background-color: var(--global-danger-color);
}

.sidebar-user .user-img .image .icondiv {
    position: absolute;
    margin-left: 38px;
    margin-top: 36px;
    width: 10px;
    height: 10px;
    background-color: #fff;
}

.sidebar-user .user-img .image i {
    position: absolute;
    margin-left: 34px;
    margin-top: 34px;
    font-size: 18px;
}

.sidebar-user .user-img .image i.verify{
    color: var(--global-success-color);
}
.sidebar-user .user-img .image i.not-verify{
    color: var(--global-danger-color);
}

.sidebar-user .user-img .level {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 12px;
    padding-top: 8px;
}

.sidebar-user .user-img .level i {
    margin-right: 6px;
    margin-left: 4px;
    padding-top: 2px;
    color: var(--global-yellow-color);
}

.sidebar-user .user-img .level label {
    cursor: pointer;
}

.sidebar-user .user-info {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.sidebar-user .user-info label:nth-child(2), .sidebar-user .user-info label:nth-child(3) {
    font-size: 14px;
    font-weight: 500;
    width: 175px;
    overflow: hidden;
    overflow-wrap: break-word;
}

/*========================= sidebar koyu tema */

.sidebar a .toggle-switch{
    display: inline-block;
    margin-left: 30px;
    vertical-align: middle;
    transition: all 400ms ease;
}

.sidebar a .toggle-switch input{
    opacity: 0;
    position: absolute;
}

.sidebar a .toggle-switch input:checked + label div{
    transform: translateX(19px);
    background-color: #333;
}

.sidebar a .toggle-switch input:checked + label{
    background-color: #ccc;
}

.sidebar a .toggle-switch label{
    width: 40px;
    height: 20px;
    background-color: #333;
    display: flex;
    border-radius:50px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    transform: scale(1.5);
    cursor: pointer;
}

.sidebar a .toggle-switch label div{
    width: 15px;
    height: 71%;
    background-color: #ddd;
    position: absolute;
    top: 2.9px;
    left: 3px;
    border-radius: 50%;
    transition: transform 0.2s linear;
}

/*========================= koyu tema aktif düzenlemeleri */

body.dark .sidebar{
    background-color: var(--global-dark1-color);
    color: #eee;
    border-color: #2b2b2b;
}

body.dark .sidebar a{
    color: #fff;
}

body.dark #react-tiny-popover-container .submenu a {
    color: #eee;
}

body.dark .sidebar-links a:not(.notclickable):hover, body.dark #react-tiny-popover-container .submenu a:not(.notclickable):hover{
    color: var(--global-yellow-color);
}

body.dark .sidebar-links a:not(.notclickable).active, body.dark #react-tiny-popover-container .submenu a:not(.notclickable).active{
    font-weight: bold;
    color: var(--global-yellow-color);
}

body.dark .sidebar-links a .count {
    background-color: #444;
}

body.dark .sidebar-links a:not(.notclickable):hover .count, body.dark .sidebar-links a:not(.notclickable).active .count {
    background-color: #333;
}

body.dark #react-tiny-popover-container .submenu {
    background-color: var(--global-dark1-color);
    box-shadow: 0px 0px 5px #454545;
}

body.dark .sidebar-links a.active .mark {
    background-color: var(--global-yellow-color);
}

body.dark .sidebar-user .user-img .image.half-verify {
    background-image: linear-gradient(to left, var(--global-success-color) 50%, #8e8e8e 50%);
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (min-width: 1200px){
    body.sidebar-close .sidebar {
        width: 110px;
    }
    body.sidebar-close .sidebar-logo {
        width: 78px;
    }
    body.sidebar-close .sidebar-openclose {
        margin-left: 95px;
    }
    body.scrolled .sidebar-openclose {
        margin-top: -3px;
    }
    body.sidebar-close .sidebar-links a {
        width: 80px;
    }
    body.sidebar-close #react-tiny-popover-container .submenu {
        left: calc(100% + -130px);
    }
    body.sidebar-close .sidebar-logo div, body.sidebar-close .sidebar-links a span, body.sidebar-close .sidebar-links a .count, body.sidebar-close .sidebar-links a .submenu-arrow, body.sidebar-close .sidebar a .toggle-switch, body.sidebar-close .sidebar-user .user-info, body.sidebar-close .sidebar-user-level label {
        opacity: 0;
        transform: translateX(-100%) scale(0);
    }
    body.sidebar-close .sidebar-links a .count {
        transition: none;
    }
    body.sidebar-close .sidebar-links a .mark {
        margin-right: 41px;
    }
    body.sidebar-close .topsectionarea {
        width: calc(100% - 110px);
    }
    body.sidebar-close .sidebar-user {
        width: 60px;
    }
    body.sidebar-close .sidebar-user-level {
        width: 40px;
        margin-left: 43px;
    }
}

@media screen and (max-width: 1200px){
    body.sidebar-close .sidebar {
        left: -350px;
    }
    body.sidebar-close .topsectionarea {
        width: 100%;
    }
    body.scrolled .topsectionarea .sidebar-openclose {
        margin-top: -2px;
    }
    .sidebar .sidebar-openclose {
        margin-left: 242px;
        position: absolute;
    }
    .sidebar-fade {
        display: none;
        justify-content: center;
        align-items: center;
        height: 99.9vh;
        position: fixed;
        width: 100%;
        z-index: 999;
        background-color: #00000080;
        animation-duration: 1s;
    }
    body.dark .sidebar-fade {
        background-color: #000000a1;
    }
}

@media screen and (max-width: 600px){
    .sidebar {
        width: 275px;
    }
    .sidebar .sidebar-openclose {
        width: 25px;
        height: 25px;
        font-size: 16px;
        margin-left: 230px;
    }
    .sidebar-logo img {
        width: 45px;
        height: 55px;
    }
    .sidebar-logo div {
        font-size: 32px;
    }
    .sidebar-links a {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .sidebar-links a i {
        margin-right: 15px;
    }
    .sidebar-links a .signal {
        width: 18px;
        height: 18px;
        font-size: 10px;
        padding-top: 0px;
        padding-right: 1px;
    }
    .sidebar-links a i.submenu-arrow {
        font-size: 10px;
    }
    #react-tiny-popover-container .submenu {
        min-width: 200px;
        padding-right: 5px;
    }
    #react-tiny-popover-container .submenu a {
        font-size: 12px;
    }
    #react-tiny-popover-container .submenu a i {
        font-size: 10px;
        min-width: 20px;
        margin-right: 0px;
    }
    .sidebar-user .user-img .image img {
        width: 40px;
    }
    .sidebar-user .user-img .image {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }
    .sidebar-user .user-img .image i {
        margin-left: 26px;
        margin-top: 26px;
        font-size: 14px;
    }
    .sidebar-user .user-img .image .icondiv {
        margin-left: 28px;
        margin-top: 28px;
        border-radius: 10px;
    }
    .sidebar-user .user-img .level {
        font-size: 9px;
    }
    .sidebar-user .user-img .level i {
        margin-right: 5px;
        padding-top: 1px;
    }
    .sidebar-user .user-info label:first-child {
        font-size: 12px;
    }
    .sidebar-user .user-info label:nth-child(2), .sidebar-user .user-info label:nth-child(3) {
        font-size: 10px;
    }
}

@media screen and (max-width: 500px){
    .sidebar {
        width: 200px;
    }
    .sidebar-logo {
        margin-left: 15px;
    }
    .sidebar-logo img {
        width: 35px;
        height: 40px;
    }
    .sidebar-logo div {
        font-size: 22px;
        padding-top: 2px;
    }
    .sidebar .sidebar-openclose {
        width: 20px;
        height: 20px;
        font-size: 12px;
        margin-left: 165px;
        margin-top: 16px;
    }
    .sidebar-links a {
        font-size: 10px;
        margin-bottom: 10px;
    }
    .sidebar-links a .mark {
        width: 3px;
        margin-right: 10px;
    }
    .sidebar-links a i {
        margin-right: 10px;
    }
    .sidebar-links a .signal {
        width: 14px;
        height: 14px;
        font-size: 8px;
        margin-left: 6px;
    }
    .sidebar-links a i.submenu-arrow {
        font-size: 8px;
        padding-top: 2px;
    }
    #react-tiny-popover-container .submenu {
        min-width: 150px;
        padding: 8px;
        padding-right: 3px;
    }
    #react-tiny-popover-container .submenu a {
        font-size: 8px;
    }
    #react-tiny-popover-container .submenu a i {
        font-size: 8px;
        min-width: 15px;
        margin-right: 0px;
    }
    .sidebar-user {
        margin-left: 10px;
        margin-top: 40px;
    }
    .sidebar-user .user-img .image {
        width: 35px;
        height: 35px;
        margin-right: 7px;
    }
    .sidebar-user .user-img .image img {
        width: 35px;
    }
    .sidebar-user .user-info label:first-child {
        font-size: 10px;
    }
    .sidebar-user .user-info label:nth-child(2), .sidebar-user .user-info label:nth-child(3) {
        font-size: 8px;
    }
}

@media screen and (max-width: 400px){
    .sidebar {
        width: 150px;
    }
    .sidebar-logo img {
        width: 25px;
        height: 30px;
    }
    .sidebar-logo div {
        font-size: 18px;
        padding-top: 0px;
    }
    .sidebar .sidebar-openclose {
        width: 15px;
        height: 15px;
        font-size: 8px;
        margin-left: 125px;
        margin-top: 14px;
        border-radius: 3px;
    }
    .sidebar-links a {
        font-size: 8px;
        margin-bottom: 7px;
    }
    .sidebar-links a .mark {
        width: 3px;
        margin-right: 7px;
    }
    .sidebar-links a i {
        margin-right: 7px;
    }
    .sidebar-links a .signal {
        width: 10px;
        height: 10px;
        font-size: 6px;
        margin-left: 4px;
        padding-right: 0.5px;
    }
    .sidebar-links a i.submenu-arrow {
        font-size: 6px;
        padding-top: 1px;
    }
    #react-tiny-popover-container .submenu {
        min-width: 100px;
        padding: 6px 7px;
        padding-right: 2px;
    }
    #react-tiny-popover-container .submenu a {
        font-size: 7px;
        margin-bottom: 5px;
    }
    #react-tiny-popover-container .submenu a i {
        font-size: 6px;
        min-width: 10px;
        margin-right: 0px;
    }
    .sidebar-user {
        margin-left: 5px;
        margin-top: 30px;
    }
    .sidebar-user .user-img .image {
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }
    .sidebar-user .user-img .image img {
        width: 25px;
    }
    .sidebar-user .user-info label:first-child {
        font-size: 8px;
    }
    .sidebar-user .user-info label:nth-child(2), .sidebar-user .user-info label:nth-child(3) {
        font-size: 6px;
    }
}
